import React, { useState, useEffect } from "react";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { firestore } from "../../../firebaseConfig";
import { MenuItem, MenuCategory, Ingredient, Restaurant } from "../../../types";
import { AnimatePresence } from "framer-motion";
import MenuForm from "./MenuForm";
import IngredientForm from "./IngredientForm";
import CategoryForm from "./CategoryForm";
import MenuItemCard from "./MenuItemCard";
import AllergenForm from "./AllergenForm";

const variants = {
	visible: { opacity: 1, scale: 1 },
	hidden: { opacity: 0, scale: 0 },
};

const AdminMenu = () => {
	const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
	const [categories, setCategories] = useState<MenuCategory[]>([]);
	const [ingredients, setIngredients] = useState<Ingredient[]>([]);
	const [allergens, setAllergens] = useState<string[]>([]);
	const [restaurants] = useState<Restaurant[]>([
		{ id: 0, name: "Mechelen" },
		{ id: 1, name: "Antwerp" },
		{ id: 2, name: "Smash" },
	]);

	const [activeForm, setActiveForm] = useState(0);
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
	const [selectedIngredients, setSelectedIngredients] = useState<string[]>([]);
	const [editingItem, setEditingItem] = useState<MenuItem | null>(null);
	const [addingItem, setAddingItem] = useState<boolean>(false);
	const [filterNoAllergens, setFilterNoAllergens] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 5;
	const [filterNoCategory, setFilterNoCategory] = useState(false);
	const [filterWithThumbnail, setFilterWithThumbnail] = useState(false);
	const [filterWithoutThumbnail, setFilterWithoutThumbnail] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			const itemsSnapshot = await getDocs(collection(firestore, "menuItems"));
			const categoriesSnapshot = await getDocs(collection(firestore, "menuCategories"));
			const ingredientsSnapshot = await getDocs(collection(firestore, "menuIngredients"));
			const allergensSnapshot = await getDocs(collection(firestore, "allergens"));

			setMenuItems(itemsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as MenuItem[]);
			setCategories(categoriesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as MenuCategory[]);
			setIngredients(ingredientsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as Ingredient[]);
			setAllergens(allergensSnapshot.docs.map(doc => doc.data().name as string));

		};

		fetchData();
	}, []);

	const deleteMenuItem = async (id: string) => {
		await deleteDoc(doc(firestore, "menuItems", id));
		setMenuItems(menuItems.filter(mi => mi.id !== id));
	};

	const handleEditMenuItem = (item: MenuItem) => {
		setEditingItem(null);
		setTimeout(() => {
			setEditingItem(item);
			setActiveForm(0);
		}, 0);
	};

	const filteredMenuItems = menuItems.filter(item => {
		const matchesSearchTerm = item.name.toLowerCase().includes(searchTerm.toLowerCase());
		const matchesCategory = selectedCategory ? item.category === selectedCategory : true;
		const matchesIngredients = selectedIngredients.length > 0 ? selectedIngredients.every(ing => item.ingredients.includes(ing)) : true;
		const matchesNoCategory = filterNoCategory ? !item.category : true;
		const matchesNoAllergens = filterNoAllergens ? !item.allergens || item.allergens.length === 0 : true;
		const matchesWithThumbnail = filterWithThumbnail ? Boolean(item.thumbnailUrl) : true;
		const matchesWithoutThumbnail = filterWithoutThumbnail ? !item.thumbnailUrl : true;

		return (
			matchesSearchTerm &&
			matchesCategory &&
			matchesIngredients &&
			matchesNoCategory &&
			matchesNoAllergens &&
			matchesWithThumbnail &&
			matchesWithoutThumbnail
		);
	});

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = filteredMenuItems.slice(indexOfFirstItem, indexOfLastItem);

	const totalPages = Math.ceil(filteredMenuItems.length / itemsPerPage);

	const renderPageNumbers = () => {
		const pageNumbers = [];
		for (let i = 1; i <= totalPages; i++) {
			pageNumbers.push(
				<button
					key={i}
					onClick={() => setCurrentPage(i)}
					className={`px-3 py-1 mx-1 ${currentPage === i ? "bg-torch-red-500 text-white" : "bg-gray-200"}`}
				>
					{i}
				</button>
			);
		}
		return pageNumbers;
	};

	const exportToJson = () => {
		const dataStr = JSON.stringify(menuItems, null, 2);
		const dataUri = `data:application/json;charset=utf-8,${encodeURIComponent(dataStr)}`;

		const exportFileDefaultName = 'menu_items.json';

		const linkElement = document.createElement('a');
		linkElement.setAttribute('href', dataUri);
		linkElement.setAttribute('download', exportFileDefaultName);
		linkElement.click();
	};

	return (
		<div className="min-h-screen pt-44 pb-24">
			<div className="w-[85%] mx-auto mb-5">
				<div className="flex justify-center items-center gap-5">
					<div
						onClick={() => setActiveForm(0)}
						className="flex gap-2 text-2xl mt-4 border p-3 rounded cursor-pointer hover:border-torch-red-500 transition-all"
					>
						<div className="font-beastie-bold tracking-wide">NEW ITEM</div>
					</div>
					<div
						onClick={() => setActiveForm(1)}
						className="flex gap-2 text-2xl mt-4 border p-3 rounded cursor-pointer hover:border-torch-red-500 transition-all"
					>
						<div className="font-beastie-bold tracking-wide">NEW INGREDIENT</div>
					</div>
					<div
						onClick={() => setActiveForm(2)}
						className="flex gap-2 text-2xl mt-4 border p-3 rounded cursor-pointer hover:border-torch-red-500 transition-all"
					>
						<div className="font-beastie-bold tracking-wide">NEW CATEGORY</div>
					</div>
					<div
						onClick={() => setActiveForm(3)}
						className="flex gap-2 text-2xl mt-4 border p-3 rounded cursor-pointer hover:border-torch-red-500 transition-all"
					>
						<div className="font-beastie-bold tracking-wide">NEW ALLERGENS</div>
					</div>
					<div
						onClick={exportToJson}
						className="flex gap-2 text-2xl mt-4 border p-3 rounded cursor-pointer hover:border-blue-500 transition-all"
					>
						<div className="font-beastie-bold tracking-wide">EXPORT TO JSON</div>
					</div>
				</div>
			</div>
			<div className="grid grid-cols-2 gap-10 w-[85%] mx-auto">
				<AnimatePresence>
					<div className="flex flex-col">
						{activeForm === 0 && <MenuForm key={editingItem?.id || 'new'} editingItem={editingItem}
                                                       setEditingItem={setEditingItem}
                                                       setAddingItem={setAddingItem}
                                                       categories={categories}
                                                       ingredients={ingredients}
                                                       restaurants={restaurants}
                                                       menuItems={menuItems}
                                                       setMenuItems={setMenuItems}
                                                       variants={variants} />}
						{activeForm === 1 &&
                            <IngredientForm ingredients={ingredients} setIngredients={setIngredients} variants={variants} />}
						{activeForm === 2 &&
                            <CategoryForm categories={categories} setCategories={setCategories} restaurants={restaurants}
                                          variants={variants} />}
						{activeForm === 3 &&
                            <AllergenForm allergens={allergens} setAllergens={setAllergens} variants={variants}
                            />}
					</div>
				</AnimatePresence>

				<div className="">
					<div className="uppercase text-torch-red-500">
						<h1 className="font-beastie-bold text-5xl">Currently Added Items</h1>
					</div>
					<div className="flex flex-col justify-center gap-5">
						<div>
							<input
								type="text"
								placeholder="Search Menu Items"
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
								className="border p-2 rounded"
							/>
							<select
								onChange={(e) => setSelectedCategory(e.target.value)}
								className="border p-2 rounded ml-2"
							>
								<option value="">All Categories</option>
								{categories.map(cat => (
									<option key={cat.id} value={cat.name}>{cat.name}</option>
								))}
							</select>
							<label className="ml-2">
								<input
									type="checkbox"
									checked={filterNoCategory}
									onChange={() => setFilterNoCategory(!filterNoCategory)}
									className="mr-2"
								/>
								No Category
							</label>
							<label className="ml-2">
								<input
									type="checkbox"
									checked={filterNoAllergens}
									onChange={() => setFilterNoAllergens(!filterNoAllergens)}
									className="mr-2"
								/>
								No Allergens
							</label>
							<label className="ml-2">
								<input
									type="checkbox"
									checked={filterWithThumbnail}
									onChange={() => setFilterWithThumbnail(!filterWithThumbnail)}
									className="mr-2"
								/>
								With Thumbnail
							</label>
							<label className="ml-2">
								<input
									type="checkbox"
									checked={filterWithoutThumbnail}
									onChange={() => setFilterWithoutThumbnail(!filterWithoutThumbnail)}
									className="mr-2"
								/>
								No Thumbnail
							</label>
						</div>
					</div>
					<div className="mb-4"></div>
					<div>
						<ul>
							{currentItems.map(item => (
								<MenuItemCard key={item.id} item={item} categories={categories}
								              restaurants={restaurants}
								              deleteMenuItem={deleteMenuItem} setEditingItem={handleEditMenuItem} />
							))}
						</ul>
					</div>
					<div className="flex flex-wrap justify-center mt-4">
						{renderPageNumbers()}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdminMenu;
