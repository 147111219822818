import { motion, useAnimation } from "framer-motion";
import { useEffect, useState } from "react";
import { useScroll } from "framer-motion";

interface FloatingMenuProps {
	isFooterVisible: boolean;
}

type Restaurant = 'mechelen' | 'antwerp' | 'smash';
type Service = 'bookTable' | 'uberEats' | 'takeout';

const links: Record<Service, Record<Restaurant, string>> = {
	bookTable: {
		mechelen: "https://beastie-burgers-mechelen.resos.com/booking",
		antwerp: "https://beastie-burgers-1721227378.resos.com/booking",
		smash: "https://beastie-smash-1721235108.resos.com/booking"
	},
	uberEats: {
		mechelen: "https://www.ubereats.com/store/beastie-burgers/CCbBSSxwR2WCCMsnahqGoA",
		antwerp: "https://www.ubereats.com/store/beastie-burgers/YKf2Z_CMRCSjizG6XN4R3A",
		smash: "https://www.ubereats.com/store/beastie-burgers/ylbbmqv-Wue7aS0EAVEzBw"
	},
	takeout: {
		mechelen: "https://takeout.beastie.be/beastie-burgers-mechelen/nl/products",
		antwerp: "https://takeout.beastie.be/beastie-burgers-antwerpen/nl/products",
		smash: "https://takeout.beastie.be/beastie-smash/nl/home"
	}
};

function FloatingMenu({ isFooterVisible }: FloatingMenuProps) {
	const controls = useAnimation();
	const { scrollY } = useScroll();
	const [expanded, setExpanded] = useState(false);
	const [selectedService, setSelectedService] = useState<Service | null>(null);

	useEffect(() => {
		if (isFooterVisible) {
			controls.start({ y: 120, opacity: 0 });
		} else {
			const unsubscribe = scrollY.onChange((latest) => {
				if (latest > window.innerHeight) {
					controls.start({ y: -10, opacity: 1 });
				} else {
					controls.start({ y: 120, opacity: 0 });
				}
			});

			return () => unsubscribe();
		}
	}, [controls, scrollY, isFooterVisible]);

	const handleOptionClick = (service: Service) => {
		setExpanded(true);
		setSelectedService(service);
	};

	const handleRestaurantClick = (restaurant: Restaurant) => {
		if (selectedService) {
			const url = links[selectedService][restaurant];
			window.open(url, "_blank");
		}
	};

	const handleClose = () => {
		setExpanded(false);
		setSelectedService(null);
	};

	return (
		<motion.div
			className="fixed bottom-5 z-[9999] right-0 left-0 mx-auto w-11/12 md:w-1/3"
			initial={{ y: 100, opacity: 0 }}
			animate={controls}
			transition={{ type: "spring", stiffness: 300, damping: 30 }}
		>
			{expanded && (
				<motion.div
					initial={{ opacity: 0, height: 0 }}
					animate={{ opacity: 1, height: 'auto' }}
					transition={{ duration: 0.5 }}
					className="bg-torch-red-500 p-4 border-2 rounded-2xl mb-5"
				>
					<div className="text-white">
						<div className="grid grid-cols-3 gap-3">
							<div
								className="w-full border-2 border-white text-center text-sm cursor-pointer transition-all font-montserrat font-black rounded-2xl px-1 py-3 flex justify-center items-center hover:bg-white hover:text-torch-red-500"
								onClick={() => handleRestaurantClick('mechelen')}
							>
								<h1>Mechelen</h1>
							</div>
							<div
								className="w-full border-2 border-white text-center text-sm cursor-pointer transition-all font-montserrat font-black rounded-2xl px-1 py-3 flex justify-center items-center hover:bg-white hover:text-torch-red-500"
								onClick={() => handleRestaurantClick('antwerp')}
							>
								<h1>Antwerp</h1>
							</div>
							<div
								className="w-full border-2 border-white text-center text-sm cursor-pointer transition-all font-montserrat font-black rounded-2xl px-1 py-3 flex justify-center items-center hover:bg-white hover:text-torch-red-500"
								onClick={() => handleRestaurantClick('smash')}
							>
								<h1>Smash</h1>
							</div>
						</div>
					</div>
				</motion.div>
			)}
			<div className={`bg-torch-red-500 rounded-2xl border-2 shadow-lg`}>
				<div className="p-2 text-white grid grid-cols-3 justify-center items-center gap-3 h-full">
					{!expanded && (
						<>
							<div
								className="w-full border-2 border-white text-center text-xs md:text-sm cursor-pointer transition-all font-montserrat font-black rounded-2xl px-1 py-3 flex justify-center items-center hover:bg-white hover:text-torch-red-500"
								onClick={() => handleOptionClick('uberEats')}
							>
								<h1>UBER EATS</h1>
							</div>
							<div
								className="w-full border-2 text-center border-white text-xs md:text-sm cursor-pointer transition-all font-montserrat font-black rounded-2xl px-1 py-3 flex justify-center items-center hover:bg-white hover:text-torch-red-500"
								onClick={() => handleOptionClick('bookTable')}
							>
								<h1>BOOK A TABLE</h1>
							</div>
							<div
								className="w-full border-2 text-center border-white text-xs md:text-sm cursor-pointer transition-all font-montserrat font-black rounded-2xl px-1 py-3 flex justify-center items-center hover:bg-white hover:text-torch-red-500"
								onClick={() => handleOptionClick('takeout')}
							>
								<h1>TAKEOUT</h1>
							</div>
						</>
					)}
					{expanded && (
						<div
							className="w-full col-span-3 border-2 border-white text-sm cursor-pointer transition-all font-montserrat font-black rounded-2xl px-1 py-3 flex justify-center items-center hover:bg-white hover:text-torch-red-500"
							onClick={handleClose}
						>
							<h1>CLOSE</h1>
						</div>
					)}
				</div>
			</div>
		</motion.div>
	);
}

export default FloatingMenu;
