import React, { useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import { firestore } from "../../../firebaseConfig";
import { motion } from "framer-motion";

interface AllergenFormProps {
	allergens: string[];
	setAllergens: (allergens: string[]) => void;
	variants: any;
}

const AllergenForm: React.FC<AllergenFormProps> = ({ allergens, setAllergens, variants }) => {
	const [allergenNames, setAllergenNames] = useState<string>("");

	const addAllergens = async () => {
		if (allergenNames.trim() === "") return;
		const newAllergens = allergenNames.split(',').map(name => name.trim()).filter(name => name);
		const newAllergenDocs = newAllergens.map(name => ({ name }));
		const docRefs = await Promise.all(newAllergenDocs.map(allergen => addDoc(collection(firestore, "allergens"), allergen)));
		setAllergens([...allergens, ...newAllergens]);
		setAllergenNames("");
	};

	return (
		<motion.div variants={variants} initial="hidden" animate="visible" className="w-full bg-torch-red-500 rounded-xl shadow">
			<div className="text-5xl text-white p-5">
				<h1 className="font-beastie">BEASTIE</h1>
				<h1 className="font-beastie-bold">NEW ALLERGEN</h1>
			</div>

			<div className="m-5">
				<div className="flex flex-col">
					<label className="font-beastie-bold text-white text-2xl uppercase">Names: </label>
					<input
						type="text"
						placeholder="Enter allergen names separated by commas"
						value={allergenNames}
						onChange={(e) => setAllergenNames(e.target.value)}
						className="p-2 rounded"
					/>
				</div>

				<div className="flex justify-end mt-4">
					<button onClick={addAllergens} className="bg-white text-torch-red-500 p-2 rounded w-auto">
						ADD ALLERGEN(S)
					</button>
				</div>
			</div>
		</motion.div>
	);
};

export default AllergenForm;
