import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {addDoc, collection, getDocs} from "firebase/firestore";
import {firestore} from "../../../firebaseConfig";

function NewJob({ variants, active }: any) {
	const [categories, setCategories] = useState<string[]>([]);
	const [locations, setLocations] = useState<string[]>([]);
	const [name, setName] = useState("");
	const [category, setCategory] = useState("");
	const [location, setLocation] = useState("");

	useEffect(() => {
		const fetchData = async () => {
			const categorySnapshot = await getDocs(collection(firestore, "categories"));
			const locationSnapshot = await getDocs(collection(firestore, "locations"));

			setCategories(categorySnapshot.docs.map(doc => doc.data().name));
			setLocations(locationSnapshot.docs.map(doc => doc.data().name));
		};

		fetchData();
	}, []);

	const handleSubmit = async () => {
		await addDoc(collection(firestore, "jobs"), {
			name,
			category,
			location,
		});

		setName("");
		setCategory("");
		setLocation("");
	};

	return (
		<motion.div variants={variants} initial="hidden" animate="visible" className="w-full bg-torch-red-500 rounded-xl shadow">
			<div className="text-5xl text-white p-5">
				<h1 className="font-beastie">BEASTIE</h1>
				<h1 className="font-beastie-bold">NEW JOB</h1>
			</div>
			<div className="m-5">
				<div className="flex flex-col">
					<label className="font-beastie-bold text-white text-3xl uppercase">Job Name:</label>
					<input
						type="text"
						placeholder="ex. Software Engineer"
						className="border rounded-lg w-full p-2 font-beastie-bold text-xl"
						value={name}
						onChange={(e) => setName(e.target.value)}
						required
					/>
				</div>
				<div className="flex flex-col mt-5">
					<label className="font-beastie-bold text-white text-3xl uppercase">Category:</label>
					<select
						className="border rounded-lg w-full p-2 font-beastie-bold text-xl"
						value={category}
						onChange={(e) => setCategory(e.target.value)}
						required
					>
						<option value="">Select Category</option>
						{categories.map((cat, idx) => (
							<option key={idx} value={cat}>{cat}</option>
						))}
					</select>
				</div>
				<div className="flex flex-col mt-5">
					<label className="font-beastie-bold text-white text-3xl uppercase">Location:</label>
					<select
						className="border rounded-lg w-full p-2 font-beastie-bold text-xl"
						value={location}
						onChange={(e) => setLocation(e.target.value)}
						required
					>
						<option value="">Select Location</option>
						{locations.map((loc, idx) => (
							<option key={idx} value={loc}>{loc}</option>
						))}
					</select>
				</div>
			</div>
			<div className="flex justify-center items-center">
				<div
					onClick={handleSubmit}
					className="hover:bg-torch-red-500 py-3 px-10 rounded hover:text-white text-3xl font-beastie-bold bg-white m-5 shadow text-torch-red-500 transition-all cursor-pointer"
				>
					SHARE
				</div>
			</div>
		</motion.div>
	);
}

export default NewJob;
