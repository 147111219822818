import React from "react";

const BeastieLoader = () => {
	return (
		<div className="flex justify-center items-center h-screen bg-torch-red-500">

			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 582.89 350.23"
				className="animate-pulse w-32 h-32"
			>
				<style>
					{`.cls-1 { fill: #fff; } .cls-2 { fill: #fff; } .cls-3 { fill: #fff; }`}
				</style>
				<g id="Layer_2_1_">
					<g id="Layer_1-2">

						<path className="cls-1" d="M127.7,113.4c5-0.9,10.1-1.9,15.2-2.7c3.8-0.6,7.9-1.4,10,3.1c2.2,4.7,5.6,9.7,0.9,14.7
			c-5.7,6.2-11,13.2-17.9,17.9c-5.2,3.5-7.8,9-12.8,12.5c-5.2,3.6-9.7,8.1-14.9,11.7c-9.2,6.3-18.5,12.4-28,18.2
			c-6.4,3.9-9.5,2.8-13.4-3.8c-0.7-1.1-1.2-2.6-2.2-3.3c-6.5-4.3-5.1-9.3-2.7-15.5c8.8-22.4,16.9-45,25.4-67.5
			c1.6-4.1,3.6-8.1,5.4-12.1c-0.4-0.4-0.8-0.8-1.1-1.3c-2.2,1.8-4.3,4-6.8,5.4c-3.6,1.7-7.2,3.3-11,4.6c-0.3,0.1-0.9,0-0.9-0.1
			c-2.1-4.5-4.7-8.8-5.8-13.5c-0.4-1.6,2.6-5,4.8-6c24-10.6,48.5-19.5,75.2-20.8c1.5-0.1,3-0.8,4.4-0.7c5.2,0.6,10.9,0,13.8,5.9
			c3.5,7.1,5.5,13.6-1,20.8c-10.2,11.2-21.7,21.2-34.1,29.7c-0.9,0.6-1.9,1.2-2.8,1.9L127.7,113.4z M135.8,124.3
			c-14.1,1.4-26.8,6.1-39.1,12.9l-4.3-3.2c-4.1,11.7-8,23.1-12.4,35.7C100.8,158.4,114.1,148.4,135.8,124.3L135.8,124.3z
			 M147.1,71.3l-0.5-1.7l-35.8,8c5.5,4.4,3.4,7.7,1.6,11.1c-3.5,6.6-6.8,13.3-10.3,20.3C110.7,108.7,140.5,83.3,147.1,71.3
			L147.1,71.3z"/>
						<path className="cls-1" d="M33.9,231.2l-12,5.1L22,237l5.4-0.5l0.2,0.6c-4,1.9-8,3.9-12.2,5.5c-1.3,0.5-4.1,0.1-4.4-0.6
			c-1.9-4.2-3.3-8.6-4.2-13.1c-0.2-1.2,2.3-3.4,4-4.5c14.6-9.3,30.8-14.8,47.4-19c12.9-3.2,25.8-6.6,39.3-6.1
			c5.1,0.2,8.2,1.7,9.4,7.2c2.4,10.4,2.2,11-6.6,17.7c-13.8,10.6-27.4,21.5-40.7,33.2c4.7-1.1,9.3-2.2,14-3.2
			c2.7-0.8,5.5-1.3,8.3-1.6c3.2-0.2,7.4-2.4,9.1,2.3c2,5.6,6.2,11.3,0.8,17.2c-21.2,22.6-44.6,42.8-70.7,59.6
			c-1.4,0.9-2.9,1.6-4.3,2.5c-3.1,2-5.4,1.6-7.5-1.6c-1.6-2.1-3.4-4-5.5-5.6c-3.3-2.9-4.8-6-3.1-10.5c8.7-22.1,17.2-44.2,25.7-66.4
			C28.8,244.2,31.1,238.4,33.9,231.2z M23.4,309.2c20.1-9.3,33.8-25.8,48.5-41.5c-12.6,2.8-25,6.2-37.3,9.8c-0.6,0.2-1,1.5-1.3,2.4
			C30,289.6,26.7,299.4,23.4,309.2L23.4,309.2z M43.5,253.4c14.1-10.4,27.6-20.4,37.4-35l-30.2,5.9C57.8,236.9,47,243.8,43.5,253.4
			L43.5,253.4z"/>
						<path className="cls-1" d="M262.2,204.1l-0.4-0.2c-1.9,4.2-6.9,5.1-9.4,8c-4.3,5-3,4.5-8.3,7.8c-1.1,0.8-2,1.8-2.7,2.9
			c-3.1,4-6.1,8.1-9.3,12.3c3.8,8.6,7.8,17.9,12,27.4l-2.7-0.5c2.6,3,1.3,9.3,7.9,8.7c0.8-0.1,2,1.9,2.8,3c1.7,2.7,3.3,5.5,5.3,9
			c-4.6,3.2-9.2,6.5-14,9.6c-0.7,0.5-2.8,0-3.4-0.8c-2.1-2.6-3.9-5.3-5.5-8.2c-6.2-11.9-12.3-23.9-18.4-35.8c-0.4-0.8-1.3-1.4-2.7-3
			c-4.7,9.2-9.9,17.6-13.4,26.6l1.7,0.9l2.9-4.2l0.8,0.4c-2.9,6.7-5.7,13.4-8.6,20c-0.6,1.2-1.2,2.4-1.6,3.6c-1.1,3.9-2,9.3-6.3,9.1
			c-5.1-0.3-10.4-3-15-5.6c-1.1-0.6-0.8-5.3,0.2-7.5c8-17.7,16.4-35.2,24.6-52.7c3.4-7.3,6.8-14.7,10.1-22.1
			c1.5-3.3,2.5-6.8,4.1-10.9c-6.4,2-8.1-1.7-8.8-6.1c-0.4-2.9-0.5-5.9-0.1-8.9c0.3-1.5,1.3-2.8,2.7-3.5c10.1-3.5,20.2-7.4,30.6-10
			c11.4-2.8,23.2-4.5,34.8-6.5c2.6-0.3,5.3-0.3,7.9,0.2c1.4,0.3,2.7,1,3.6,2.2c4.2,6.1,3.5,15.3-1.9,20.3
			c-4.8,4.5-9.9,8.7-14.9,12.9c-1.1,0.7-2.2,1.3-3.4,1.7l0.1,0.1l-1-0.7L262.2,204.1L262.2,204.1z M226.2,216.2
			c12.8-8.8,25-18.4,34.9-30.7c-4.7,0.1-9.4,0.7-14,1.9c-5.4,1.4-11.7,2-12.1,9.8c-0.1,0.9-1.1,1.6-1.5,2.5
			c-2.6,5.3-5.2,10.7-7.8,16l-0.3,1L226.2,216.2z"/>
						<path className="cls-1" d="M478.6,37c-4.3,0.6-5-3.2-5.4-5.7c-0.6-4.1-0.6-8.2,0-12.3c0.2-1.3,2.5-3.2,4-3.4c18.1-3.1,36.3-5.7,54.4-9
			c7.4-1.3,14.7-3.8,22-5.9c5.4-1.5,7.1-0.6,8.6,4.7c3.5,12.8,3.5,12.7-9.2,16.1c-7.7,2.1-15.2,4.7-22.8,7c-2.5,0.9-5.2,1.2-7.8,1
			l8.1-3.2c-0.1-0.4-0.2-0.8-0.2-1.2c-6.3,1.1-12.6,2.5-19,3.4c-6.1,0.9-9.7,3.9-11.6,9.8c-1.4,4.4-3.8,8.5-5.9,12.6
			c-0.6,1.1-2,1.9-3.6,3.3c1.5,0.2,2.9,0.2,4.4,0c8.6-2,17.1-4,25.6-6.4c5.8-1.6,6.8-1.3,8.6,4.5c0.2,0.6,0.3,1.3,0.5,1.9
			c2.5,7.8,1,9.8-7.4,10.2c-6.6,0.4-13.2,0.2-19.6,1.4s-12.8,3.6-19.2,5.4c-0.5,0.1-1.2,0.3-1.3,0.6c-1.4,4.7-2.6,9.4-3.9,14.1
			c-1,3.6-1.9,7.3-3,11.8c5.2-1.6,10.6-3.3,16-4.8c7.4-2,14.8-3.8,22.2-5.8c3.5-0.9,7-2,10.5-3.1c1.7-0.6,3.3-1.6,5-2.2
			c10.2-3.8,16-0.3,16.6,10.4c0.1,1.6-2.5,4.4-4.3,4.9c-20.4,5.2-40.9,10-61.4,15.2c-5.3,1.2-10.5,3-15.5,5.3
			c-4.9,2.4-7.8,0.8-10.8-2.6s-4.2-7.4-1.8-11.9c4-7.4,7.9-15,11.8-22.5c0.5-1.2,1-2.5,1.3-3.8l0.4-0.7h-1.1l-7.4,2.2
			c-0.5-0.3-0.9-0.5-0.9-0.7c-3.2-14.1-4.5-12.4,11.7-17c4-1.1,6.4-2.6,7.5-6.6c1.5-5.4,3.3-10.8,4.9-16.2c0.1-0.4,0.2-0.7,0.3-1.1
			L478.6,37z"/>
						<path className="cls-1" d="M158.6,237.4c-9.4,12.9-18.6,25.9-28.3,38.6c-5.8,7.6-12.2,15-18.7,22.1c-4.1,4.5-5.8,4.2-9.9-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4c-9.7-5.9-6.7-13.9-3.6-21.8c10-25.5,20.1-50.9,30.2-76.3c0.6-1.6,1-3.2,1.4-4.8c1.4-6,7-8.6,12.4-5.8
			c1.2,0.6,2.6,0.7,3.6,1.4c2.7,1.8,6.1,3.8,4.7,7.6c-2.9,8.2-6.4,16.3-10,25.3l-2.1-4.6l-20.3,52.5l1.7,1.2
			c4.7-4.8,9.4-9.7,14.1-14.5c2.6-2.7,5.4-5.2,8-7.9c2.2-2.2,4.7-4.3,6.3-7c10.3-17.4,20.4-34.8,30.6-52.3c0.6-1,1.1-2,1.7-3
			c4.6-7.5,5.1-7.6,12.5-2.6c1.1,0.7,2.3,1.3,3.5,1.8c2.7,1.3,3.6,3.3,2.3,6.1c-1.6,3.5-3.1,7-4.7,10.4
			c-10.8,23.2-21.6,46.4-32.3,69.6c-0.6,1.4-1,2.8-1.5,4.2c-0.2,0.8-0.4,1.5-0.6,2.3l0.5,0.2l1.1-1.5c0.4,0.6,1.2,1.5,1,1.9
			c-3.1,8-6.3,16.1-9.7,24c-0.4,1-3.1,1.8-4.2,1.4c-3.2-1.3-6.4-2.8-9.4-4.6c-3.5-1.9-4.3-4.6-2.4-8.3c4.5-9.2,8.9-18.4,13-27.7
			c3.7-8.5,7-17.2,10.5-25.9c0.4-0.6,0.7-1.3,1.1-1.9c0.2-0.4,0.5-0.8,0.8-1.2l1.4-1.5l3.1-4.6l-1.6-1l-2.5,4.5
			c-0.5,0.6-1,1.1-1.5,1.7c-0.3,0.3-0.5,0.6-0.8,1C159.3,236.2,159,236.8,158.6,237.4z M158.6,279.8l-0.8,0.5
			c0.3,0.2,0.5,0.6,0.8,0.6c0.1,0,0.4-0.4,0.6-0.6L158.6,279.8z"/>
						<path className="cls-1" d="M313.5,237.3c-7.6,7.3-15,14.6-22.7,21.7c-6.1,5.6-12.5,10.9-18.7,16.5c-2.5,2.2-4.1,1-5.8-0.9
			c-7.6-8.3-11.3-17.8-7.9-28.8c2-6.8,4.8-13.4,8.2-19.6c5.4-9.3,11.1-18.7,18-26.9c12.6-14.9,24.7-30.4,42.3-40.2
			c1.5-0.8,2.8-1.7,4.3-2.5c7.5-4.1,11.1-2.8,14.4,5c0.6,1.4,0.6,3.2,1.6,4.1c4.5,4.5,1.5,6.9-2.1,9.8c-5.8,4.6-11.3,9.7-17,14.5
			c-1.3,1.1-2.9,1.8-4.6,1.9l5.2-7.8l-1.9-1.2c-26.3,17.9-41.5,43.4-50.3,73.3l1.3,1.3c4.3-2.9,8.9-5.3,12.8-8.6
			c6.5-5.6,12.7-11.6,18.8-17.8c1.2-1.2,0.9-3.7,1.3-5.7c0-0.2-0.3-0.6-0.4-0.9c-4-11.3-2.2-13.8,9.4-16.2c8.6-1.9,17-4.4,25.2-7.4
			c4.2-1.4,5.9,0.3,7.2,3.5c0.8,2,1.4,4.1,1.8,6.2c0.5,3.9-0.5,6.8-5.1,7.5c-2.9,0.5-5.7,1.8-8.5,2.9c-0.9,0.4-1.6,1-2,1.9
			c-7.2,15.8-14.3,31.7-21.4,47.6c-0.5,1.2-1.2,2.4-1.7,3.6c-2.7,6.9-2.5,6.6-9.1,3.7c-7.7-3.4-11.2-7.4-3.6-17.8
			c4.8-6.6,8.1-14.4,12-21.6l1.4-1.8c0-0.5,0-1,0-1.5l-0.9,0.8L313.5,237.3z"/>
						<path className="cls-1" d="M178.8,87.2c-1.5-2-3.5-3.8-4.3-6c-2.9-8.4-1-12.3,7.6-15c10.1-3.2,20.4-5.9,30.6-8.8
			c9.1-2.6,18.2-5.1,27.2-7.9c3.4-1.1,4.8,0,5.4,3.4c2.9,15.4,1.5,17.8-13.7,22c-4,1.2-8,2.2-12.1,2.9l4.9-3.6l-0.5-1.1
			c-3.4,0.8-6.9,1.6-10.3,2.2c-3.3,0.6-6.7,1-10.4,1.5l-12.6,27.3l0.8,1.2c5.2-1.7,10.4-3.3,15.6-5.1c3.1-1.1,6.1-2.5,9.2-3.8
			c2.6-1.1,4.1-0.2,4.8,2.4c0.9,3.2,2.5,6.3,2.6,9.5c0.1,1.9-1.6,5.2-3.1,5.6c-6.9,1.9-14.3,4.9-20.9,3.8c-6.1-1.1-9.6,2.3-14.4,3.3
			c-1.1,0.3-2,1.1-2.4,2.2c-3.4,8.1-6.6,16.3-9.9,24.4c-0.2,0.9-0.3,1.9-0.3,2.9c9.7-2.7,18.9-5.3,28.1-7.8s18.2-4.9,27.4-7.2
			c1.4-0.2,2.7,0.3,3.7,1.3c2,2.8,3.6,5.9,4.8,9.2c0.3,0.9-1.6,3.6-2.9,3.9c-13.3,3.5-26.5,8-40.1,9.7c-11.4,1.4-20.8,7.4-31.4,10.4
			c-2.9,0.8-4.6,0.9-6.4-1.6c-1.7-2.2-4.6-4-5.3-6.4s-0.2-6,0.9-8.6C160.7,131.2,169.8,109.1,178.8,87.2z M225.5,72.7
			C226.2,72.6,225.8,72.6,225.5,72.7z"/>
						<path className="cls-1" d="M446,221.1c-3.2,7.6-6.4,15.2-9.6,22.8c-0.1,0.3-0.4,0.6-0.6,0.8c-5.9,4.2-9.8-1.6-14.4-3.2
			c-3.3-1.2-2.5-4.1-1.3-6.8c5.1-11.3,10.2-22.6,15.2-33.9c1.8-4.1,2.5-9.3,5.4-12.3c5.3-5.5,5.2-12.9,8.5-19.1
			c1.9-3.5,4.4-7.1,0.3-11.8c-2.2-2.5-1.6-7.6-1.6-11.5c0-1.3,1.8-3.3,3.3-3.8c13.9-5.2,28-9.7,42.7-11.3c4.1-0.4,8.2-0.6,12.4-0.4
			c5.9,0.3,11.2,7.6,10.4,14.4c-0.4,2.8-1.3,6-3.1,8c-9.9,10.5-19.9,20.9-30.4,30.8c-5.9,5.6-12.7,10.1-19.6,15.6
			c2.8,4.3,5.9,8.8,8.8,13.4c2.3,3.6,4.6,7.3,6.7,11.1c0.5,0.8,0.1,2.1,0.1,3.1l-1.5-0.7c0.2,0.5,0.5,1,0.7,1.4l18.2,14
			c-1,1-2.9,2.8-4.9,4.4c-1,0.8-2.4,1.1-3.5,1.8c-8.4,5.2-8.4,5.3-15-1.7c-2.2-2.3-4.1-4.8-5.6-7.5c-6-10.8-11.7-21.6-18.4-34
			c-2.6,6.5-4.8,11.9-6.9,17.3l1.1,0.6l3-1.5L446,221.1z M477,170.1c0.2,0.2,0.3,0.4,0.5,0.5c0.7,0.1,1.8,0.5,2.1,0.2
			c1.6-1.6,2.9-3.5,4.4-5.2c4.6-5.3,9.3-10.5,14-15.7c-5.8-0.2-10.9,0.7-16.1,1.4c-7.1,0.9-12.7,3.4-14.7,11.1
			c-0.4,1.7-1.6,3.2-2.5,4.9l-10.1,19l1.7,1.4c3.8-2.5,9.3-4.1,11-7.6C469.6,175.5,473.4,173.2,477,170.1L477,170.1z M452.7,181.6
			l-2.2-1l-4.9,12.2l1.3,0.6L452.7,181.6z M443.2,223.1l-0.6-0.4c-0.5,0.5-0.9,1-1.3,1.5l1.1,0.7L443.2,223.1z"/>
						<path className="cls-1" d="M379.1,177c-3.5,1.1-5.6,1.6-7.6,2.4c-6.5,2.3-7.4,1.9-9.5-4.8c-0.5-1.7-1-3.5-1.7-5.2c-1.4-3.5-0.4-6,3.1-7
			c5.5-1.5,11.1-2.7,16.8-3.6c18.6-2.4,35.8-9.6,53.1-16.1c2.6-1,4.2-0.9,5.1,1.7c1.4,3.9,2.6,7.8,3.6,11.8c0.7,3.3-1.5,4.7-4.5,5.4
			c-9,2.1-18,4.3-27.4,5.7l4.7-3.6c-4.9,0-8,0.5-10.4,5.1c-4.3,8.2-9.7,15.8-14.6,23.6c0.2,0.4,0.5,0.8,0.7,1.2
			c3.9-1.3,7.9-2.5,11.8-4c12.2-4.6,12-3.4,12.8,7.2c0.7,9.3,0.6,9.2-8.6,10.4c-7.9,1-15.7,2.6-23.5,4.1c-1.4,0.3-2.7,1.1-3.6,2.3
			c-5,9.3-9.9,18.6-14.8,28l1.2,1.5c8.1-2.2,16.1-4.5,24.2-6.7c4.8-1.3,9.6-2.5,14.4-3.6c0.9-0.2,2.6,0,2.8,0.4
			c1.8,4.5,3.5,9.1,4.9,13.7c0.1,0.4-2.1,1.9-3.4,2.2c-16.2,4.3-32.5,8.4-48.7,12.7c-4.4,1.2-7.1,0.1-10-3.8
			c-4.8-6.4-5-12.5-1.4-19.3c7.5-14.3,15-28.7,22.3-43.2C373.8,189.8,376.1,183.9,379.1,177z"/>
						<path className="cls-1" d="M300.1,302.1c-5.9,2-12.5,2.5-17.2,7.4c-0.4,0.4-1.8,0.1-2.7-0.1c-3.4-0.8-5.7,0.7-8.8,2.1
			c-6.6,3-12.1,10-20.9,6.8c-1.4-0.5-3.6,1.4-5.5,1.7c-3.3,0.5-6.9,0-10,1.1c-6.4,2.2-12.5,5.1-18.8,7.8c-4.9,2.1-9.7,4.4-14.3,7.1
			l26.5-2.3l0.2,1.2c-2.9,0.3-5.7,0.8-8.5,1.5c-15.8,4.5-31.4,9.1-47.2,13.5c-1.4,0.4-4.3,0.4-4.5-0.2c-1.6-4.1-3.2-8.3-3.5-12.6
			c-0.1-1.7,2.8-4.5,5-5.3c17.7-6.6,35.5-13.2,53.5-19c45.5-14.8,91.7-27.3,138.8-36.1c17.8-3.3,35.8-5.7,54-4.8
			c4.7,0.2,7.5,1.7,7.3,6.5c-0.1,4.4-0.7,8.8-1.6,13.1c-0.9,3.6-3,3.6-5.7,1c-1.2-1.3-3.2-2.6-4.8-2.6c-8.4-0.1-16.9-0.1-25.3,0.5
			c-15.5,1-31,1.8-46,6.6c-6,1.9-12.9,3.5-18.9,2.6c-4.7-0.8-8.8-0.9-13.1,0.6c-2.5,0.1-4.9,0.2-7.4,0.4
			C300.5,300.5,300.3,301.5,300.1,302.1z"/>
						<path className="cls-1" d="M569.6,138.9c-13.7,8.6-25.7,19.6-35.6,32.4c4.8-1.5,9.5-3.1,14.3-4.5c7.3-2.1,14.7-4.1,22.1-5.9
			c1.6-0.4,3.2-0.3,4.8,0.3c4.7,2.3,8,15,5,19.2c-4.2,5.9-8.7,11.5-13.3,17.7c-0.8-1.5-1.1-2.1-1.3-2.4c-3.7,3.9-7.2,8.1-11.2,11.8
			c-7.7,7.1-15.6,14-23.6,20.8c-4.3,3.6-8.8,6.9-13.3,10.3s-7.1,3.1-11.7-1.2c-4.3-4.1-5.6-9.1-2.2-12.2
			c8.4-7.6,17.4-14.6,26.2-21.9c0.2,0.2,0.4,0.5,0.7,0.7l-13.3,12.7c-0.3,0.6-0.6,1.1-0.9,1.7l2-0.6c18.1-10.7,32.7-25,44.9-43.5
			c-20,2.2-35,11.3-47.8,24.3c-9-7.7-10.7-13.4-5.3-22.9c6-10.4,13-20.3,20.1-31.3c0.7,2.1,0.9,2.8,1.1,3.4l13.2-11.8l0.8-1.7
			l-1.8,0.7c0-4.2,0.8-5.2,5.4-6.6l0.3,0.4l-0.4-0.3c5.3-4.1,10.5-8.4,16.1-12.1s8.5-3,12.2,2.7c1.8,2.8,3.4,5.7,5,8.6
			c0.4,0.7,0.7,1.5,0.7,2.3c0.2,4.2-8.1,11.7-12.4,11.3c-0.1-0.1-0.2-0.2-0.2-0.4c0.1-0.3,0.2-0.6,0.3-0.9l1-1.7L569.6,138.9z"/>
						<path className="cls-1" d="M355.7,58.9l2-7.4c-14.1,7.7-23,19.2-31.8,30.9l0.7,1.3c1.6-0.5,3.2-0.9,4.8-1.4c7.6-2.5,15-5.2,22.7-7.5
			c6.9-2,10.5-0.4,15,6.3c3.2,4.7,1.3,9-1.4,12.9c-4.4,6.7-9.4,13.1-15,19l-0.1-2.3c0.1-0.4,0.2-0.8,0.2-1.2l-1,0.5
			c-4.2,4.7-8.4,9.3-12.6,13.9c-1,1.1-2.4,1.9-3.3,3c-3,3.8-5.3,8.4-9,11.4c-6.5,5-13.3,9.5-20.5,13.5c-1.1,0.7-3.9-1.2-5.8-2.2
			c-0.7-0.3-1-1.3-1.5-2c-6.1-8.1-6-10,1.7-16.7c3.4-2.9,7.1-5.4,10.7-8.1c0.9-0.5,1.8-1,2.7-1.4l-8.9,12.1
			c10.7-2.8,36.9-28.8,42.1-45.6l-39.3,19.1c-7.3-3.6-11.9-13.9-6.9-21.2c9.5-13.9,18.9-27.8,31.5-39.1c7.3-6.5,15.2-12.5,23-18.3
			c5.1-3.8,9.2-2.3,15,4.5c3.4,4,3.3,6.7-0.9,11.3c-4.5,5-9.3,9.9-13.9,14.9L355.7,58.9z M326,83.6c-0.1-0.1-0.2-0.3-0.4-0.3
			s-0.2,0.2-0.4,0.3l0.5,0.3L326,83.6z"/>
						<path className="cls-1" d="M270,108.5c-7.1,1-13.9,1.8-20.7,2.9c-1.3,0.2-2.6,1.9-3.3,3.3c-2.4,5.3-4.7,10.6-6.8,16
			c-1.1,2.8-2.2,3.5-4.6,1.4c-1.7-1.5-3.6-2.8-5.3-4.4c-2.7-2.4-4.7-4.9-0.4-8c0.8-0.6,0.8-2.6,0.8-4c-0.1-2.5-1-5.1-0.4-7.4
			c0.5-2,2.3-4.6,4-5.1c5.1-1.3,7.9-4.3,10.6-8.7c11.9-19.6,24.2-38.8,36.5-58.2c1.5-2.4,3.2-4.6,5-6.8c3.8-4.6,7.8-5.1,13.2-1.9
			c7.1,4.2,7.4,5.9,5.4,12.8c-4.2,14.7-8.2,29.4-11.5,44.3c-0.8,3.6,1.5,8,2.8,11.9c1.3,3.7-0.2,5.8-3.4,6.2
			c-3.8,0.5-4.3,2.9-4.8,5.8c-1.9,11.5-3.7,22.9-5.7,34.4c-0.9,5-5,7.1-9.8,5.1c-1.7-0.7-3.4-1.3-5-2.1c-3-1.5-4.5-3.8-3.6-7.3
			c1.7-6.9,3.5-13.8,5.1-20.8C268.8,114.9,269.3,111.7,270,108.5z M256.1,96.3c5.4-1.4,10.7-2.7,16.1-4.2c0.9-0.4,1.6-1.1,2.1-2
			c7.7-11.5,6.4-24.9,8.6-37.7c-1.5,1.3-2.8,2.8-3.9,4.5C271.4,70,263.8,83.1,256.1,96.3l-0.5,0.5l0.4,0.1L256.1,96.3z"/>
						<path className="cls-1" d="M402.6,36.7c-4.2,1.5-8.4,3-12.5,4.6c-3.8,1.5-7.4,0-8.9-3.8c-0.5-1.6-1-3.2-1.3-4.8c-1.6-6.6-0.1-8.9,7-10.3
			c13.8-2.8,27.6-5.7,41.5-8.4c9.9-2,19.9-3.6,29.8-5.6c4.5-0.9,6.2,1.4,6.7,5.2c0.4,3.3,0.5,6.6,0.3,9.9c-0.5,1.5-1.6,2.6-3.1,3
			c-10.1,1.2-20.3,2.4-30.5,2.9c-4.3,0.2-5.9,1.6-7.6,5.8c-3,7.6-7.4,14.6-11.3,21.8c-0.4,0.7-1.3,1.3-1.4,2
			c-0.9,4.9-3.1,8.3-7.9,10.6c-1.6,0.8-1.4,5.4-2,8.3c-0.2,1.6-0.8,3.1-1.8,4.4l-0.7-2.7l-10.3,25.2l-0.7-0.7
			c0.1,1.2,0.7,2.5,0.4,3.5c-3,9.3-6.1,18.6-9.4,27.8c-1.3,3.8-4.2,5-8,3.6c-1.9-0.7-3.6-1.7-5.2-2.9c-5.6-4.1-6-5.2-3.1-11.4
			c11.2-23.7,22.6-47.3,33.8-71c2.5-5.2,4.7-10.6,7.1-15.9c0.1-0.3,0.3-0.7,0.4-1L402.6,36.7z"/>
						<path className="cls-1" d="M424.1,108.3c-0.2,0.3-0.2,0.7-0.2,1c-0.3,0.4-0.5,0.7-0.8,1.1c-0.5,0.9-1,1.7-1.5,2.6
			c-0.9,1.5-3.5,3.2,0.4,4.4c-0.9,4.5-1.4,9.1-2.9,13.3c-2.1,6.1-4.7,7-10.4,4c-6.8-3.6-11.1-8.8-6.1-17.4s8.7-18.2,13.5-27.2
			c1.9-3.5,5.1-6.3,7.7-9.4c2-2.3,5.5-3.9,3.9-8.5l-3.9,4.1c0.3-1.4,0.6-2.8,1-4.2c4.2-10.8,8.3-21.7,12.7-32.4
			c2.3-5.7,4.1-6,8.8-2.3c1.4,1.1,2.8,2.3,4.3,3.4c3.9,2.7,5,6.3,2.7,10.3c-4,7.2-8.8,14.1-12.5,21.4c-5.5,10.8-10.5,21.8-15.7,32.7
			C424.9,106.2,424.5,107.3,424.1,108.3z"/>
						<path className="st1" d="M529.9,203.1l1.5-0.6l-0.9,1.4C530.3,203.6,530.1,203.3,529.9,203.1z"/>
						<path className="st1" d="M446.1,221.2l0.2-0.8l0.4,0.3L446.1,221.2L446.1,221.2z"/>
						<path className="st1" d="M356.1,59.4l-0.8,0.6c0.1-0.4,0.3-0.7,0.4-1.1L356.1,59.4z"/>
						<path className="st2" d="M263.1,204.1h-1l-0.1,0.1L263.1,204.1L263.1,204.1z"/>
						<path className="st2"
						      d="M245,263.6l-0.4,0.3c-0.1-0.1-0.3-0.3-0.3-0.4s0.2-0.2,0.3-0.3L245,263.6z"/>
						<path className="st1"
						      d="M477,170.1l0.8-0.6c-0.1,0.4-0.2,0.7-0.4,1.1C477.3,170.5,477.1,170.3,477,170.1z"/>
					</g>
				</g>
			</svg>

		</div>
	);
};

export default BeastieLoader;
