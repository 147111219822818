import { useState } from "react";
import { motion } from "framer-motion";
import {addDoc, collection} from "firebase/firestore";
import {firestore} from "../../../firebaseConfig";


function NewPosition({ variants, active }: any) {
	const [positionName, setPositionName] = useState("");
	const [positionDescription, setPositionDescription] = useState("");

	const handleSubmit = async () => {
		await addDoc(collection(firestore, "categories"), {
			name: positionName,
			description: positionDescription,
		});

		setPositionName("");
		setPositionDescription("");
	};

	return (
		<motion.div variants={variants} initial="hidden" animate="visible" className="w-full bg-torch-red-500 rounded-xl shadows">
			<div className="text-5xl text-white p-5">
				<h1 className="font-beastie">BEASTIE</h1>
				<h1 className="font-beastie-bold">NEW POSITION</h1>
			</div>
			<div className="m-5">
				<div className="flex flex-col">
					<label className="font-beastie-bold text-white text-3xl uppercase">Position Name:</label>
					<input
						type="text"
						placeholder="ex. LEAD SINGERS"
						className="border rounded-lg w-full p-2 font-beastie-bold text-xl"
						value={positionName}
						onChange={(e) => setPositionName(e.target.value)}
						required
					/>
				</div>
				<div className="flex flex-col mt-5">
					<label className="font-beastie-bold text-white text-3xl uppercase">Position Description:</label>
					<input
						type="text"
						placeholder="ex. RESTAURANT MANAGER"
						className="border rounded-lg w-full p-2 font-beastie-bold text-xl"
						value={positionDescription}
						onChange={(e) => setPositionDescription(e.target.value)}
						required
					/>
				</div>
			</div>
			<div className="flex justify-center items-center">
				<div
					onClick={handleSubmit}
					className="hover:bg-torch-red-500 py-3 px-10 uppercase hover:shadow-none rounded hover:text-white text-3xl font-beastie-bold bg-white m-5 shadow text-torch-red-500 transition-all cursor-pointer"
				>
					Save
				</div>
			</div>
		</motion.div>
	);
}

export default NewPosition;
