import { motion } from "framer-motion";
import React from "react";

interface HeadingTextProps extends React.HTMLAttributes<HTMLDivElement> {
	children: React.ReactNode;
	delay?: number; // New delay prop
}

function HeadingText({
	                     children,
	                     className,
	                     onMouseEnter,
	                     onMouseLeave,
	                     delay = 0 // Default delay is 1
                     }: HeadingTextProps) {
	const variant = {
		visible: { scale: 1, transition: { delay } },
		hidden: { scale: 0, transition: { delay } },
	};

	return (
		<motion.div
			variants={variant}
			initial="hidden"
			whileInView="visible"
			className={className}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			{children}
		</motion.div>
	);
}

export default HeadingText;
