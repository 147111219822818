import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { getDocs, collection } from "firebase/firestore";
import { firestore } from "../../../firebaseConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faClose, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import { Editor } from "@tinymce/tinymce-react";
import { Application } from "../../../types"; // Import the Application type

const variants = {
	visible: { opacity: 1, scale: 1 },
	hidden: { opacity: 0, scale: 0 },
};

interface ApplicationsProps {
	jobId: string;
	setShowApplications: (show: boolean) => void;
}

const Applications: React.FC<ApplicationsProps> = ({ jobId, setShowApplications }) => {
	const [applications, setApplications] = useState<Application[]>([]);
	const [selectedApplication, setSelectedApplication] = useState<Application | null>(null);

	useEffect(() => {
		const fetchApplications = async () => {
			const applicationSnapshot = await getDocs(collection(firestore, "applications"));
			const applicationsData = applicationSnapshot.docs.map((doc) => ({
				id: doc.id,
				...(doc.data() as Application), // Proper type casting here
			}));

			const filteredApplications = applicationsData.filter((application: Application) => application.positionId === jobId);
			setApplications(filteredApplications);
		};

		fetchApplications();
	}, [jobId]);

	return (
		<motion.div variants={variants} initial="hidden" animate="visible" className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
			<div className="bg-white p-5 rounded w-2/3">
				<div className="flex justify-between items-center mb-4">
					<h2 className="text-2xl font-beastie-bold">Applications</h2>
					<div
						onClick={() => setShowApplications(false)}
						className="bg-torch-red-500 text-white px-3 py-1 cursor-pointer rounded"
					>
						<FontAwesomeIcon icon={faClose} />
					</div>
				</div>
				<div className="flex gap-5">
					<div className="w-1/2 border p-3 rounded">
						<h3 className="font-bold mb-3">List of Applications</h3>
						{applications.map((application) => (
							<div
								key={application.id}
								className={`border p-2 rounded mb-2 cursor-pointer ${selectedApplication?.id === application.id ? "bg-gray-200" : ""}`}
								onClick={() => setSelectedApplication(application)}
							>
								<h4 className="font-bold">{application.name}</h4>
								<p>{application.email}</p>
							</div>
						))}
					</div>
					<div className="w-2/3 border p-3 rounded">
						{selectedApplication ? (
							<>
								<h3 className="font-bold mb-3">Application Details</h3>
								<p><strong>Name:</strong> {selectedApplication.name}</p>
								<p><strong>Email:</strong> {selectedApplication.email}</p>
								<p><strong>Cover Message:</strong></p>
								<Editor
									apiKey="vll80rck3f39fviylifdsav327qel4w8687iym5i73ptw7sc"
									init={{
										skin: "snow",
										icons: "thin",
										placeholder: "Write text baby... ",
										height: 250,
										menubar: true,
										plugins: [
											"advlist autolink lists link image charmap print preview anchor",
											"searchreplace visualblocks code fullscreen textcolor ",
											"insertdatetime media table paste code help wordcount",
										],
										toolbar:
											"undo redo | styleselect | fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ",

									}}
									disabled={true}
									initialValue={selectedApplication.coverMessage}
								/>
								<a
									href={`mailto:${selectedApplication.email}`}
									className="bg-torch-red-500 text-white px-3 py-1 rounded mt-4 inline-block"
								>
									<FontAwesomeIcon icon={faEnvelope} /> Send Email
								</a>
							</>
						) : (
							<p>Select an application to view details</p>
						)}
					</div>
				</div>
			</div>
		</motion.div>
	);
};

export default Applications;
