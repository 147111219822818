import React, {useRef, useState} from "react";
import { Editor } from "@tinymce/tinymce-react";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import {motion} from "framer-motion";

const firestore = getFirestore();
const storage = getStorage();

const AdminGiveaway = () => {
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [image, setImage] = useState<File | null>(null);
	const [imagePreview, setImagePreview] = useState<string | null>(null);
	const [uploading, setUploading] = useState(false);
	const fileInputRef = useRef<HTMLInputElement | null>(null);

	// Handle file input change
	const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];
			setImage(file);
			setImagePreview(URL.createObjectURL(file));
		}
	};

	const handleEditorChange = (content: string) => {
		setDescription(content);
	};

	const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.stopPropagation();
	};


	// Handle drag and drop image
	const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.stopPropagation();

		if (event.dataTransfer.files && event.dataTransfer.files[0]) {
			const file = event.dataTransfer.files[0];
			setImage(file);
			setImagePreview(URL.createObjectURL(file));
		}
	};


	const handleSubmit = async () => {
		if (!title || !description || !image) {
			alert("Please fill in all fields.");
			return;
		}

		setUploading(true);

		try {
			// Upload image to Firebase Storage
			const imageRef = ref(storage, `giveaway/${image.name}`);
			const uploadTask = uploadBytesResumable(imageRef, image);

			uploadTask.on(
				"state_changed",
				() => {}, // Handle progress if needed
				(error) => {
					alert("Image upload failed");
					setUploading(false);
				},
				async () => {
					const imageUrl = await getDownloadURL(uploadTask.snapshot.ref);

					// Add giveaway details to Firestore
					await addDoc(collection(firestore, "giveaways"), {
						title,
						description,
						imageUrl,
						date: new Date().toISOString(),
					});

					alert("Giveaway added successfully");
					setTitle("");
					setDescription("");
					setImage(null);
					setUploading(false);
				}
			);
		} catch (error) {
			console.error("Error adding document: ", error);
			setUploading(false);
		}
	};

	return (
		<div className="p-10  rounded-lg shadow-md min-h-screen grid grid-cols-3  items-center">
			<div className="col-span-1 bg-torch-red-500 p-5 mt-20 text-white rounded-2xl shadow-lg">
				<h1 className="text-5xl font-bold mb-5 font-beastie-bold">New Giveaway</h1>
				<div className="mb-4">
					<label className="block text-sm font-bold mb-2">Title</label>
					<input
						type="text"
						className="border rounded-md w-full p-2 text-black"
						value={title}
						onChange={(e) => setTitle(e.target.value)}
					/>
				</div>
				<div className="mb-4">
					<label className="block text-sm font-bold mb-2">Description</label>
					<Editor
						apiKey="vll80rck3f39fviylifdsav327qel4w8687iym5i73ptw7sc"
						value={description}
						init={{
							height: 200,
							menubar: false,
							plugins: ["link", "image", "code"],
							toolbar:
								"undo redo | formatselect | bold italic | alignleft aligncenter alignright | code",
						}}
						onEditorChange={handleEditorChange}
					/>
				</div>
				<div className="mb-4">
					<label className="block text-sm font-bold mb-2">Upload Image</label>
					<div
						className="border-dashed border-2 border-gray-300 p-4 mb-4 text-center"
						onDrop={handleDrop}
						onDragOver={handleDragOver}
					>
						<input
							type="file"
							accept="image/*"
							onChange={handleImageUpload}
							className="hidden"
							ref={fileInputRef}
						/>
						<p className="text-sm text-gray-500">Drag & drop an image here, or</p>
						<button
							type="button"
							onClick={() => fileInputRef.current?.click()}
							className="text-blue-500 underline"
						>
							browse for an image
						</button>
					</div>
				</div>
				<button
					onClick={handleSubmit}
					disabled={uploading}
					className="bg-white text-torch-red-500 font-beastie-bold text-2xl py-2 px-4 rounded disabled:bg-gray-400"
				>
					{uploading ? "Uploading..." : "Submit"}
				</button>
			</div>
			<div className="col-span-2 flex justify-center items-center p-5 mt-20">
				<div
					className="w-full h-full grid grid-cols-1 md:grid-cols-3 relative">
					<div className="relative">
						<img src={imagePreview ? imagePreview : 'https://placehold.co/1080x1080'} className="w-full object-cover"/>
					</div>
					<div
						className="col-span-2 bg-torch-red-500 w-full mx-auto p-5 flex flex-col items-center justify-center">
						<h1 className="font-beastie text-3xl md:text-5xl text-white">{title ? title : 'Please Enter Title'}</h1>
						<div className="text-white mt-3 text-center">
							<div
								dangerouslySetInnerHTML={{
									__html: description ? description : "Enter Description",
								}}
							/>
							<div className="text-end text-xl font-black">
								{new Date().toDateString()}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdminGiveaway;
