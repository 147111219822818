import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./AuthContext";

const ProtectedRoute: React.FC = () => {
	const { currentUser } = useAuth();

	return currentUser ? <Outlet /> : <Navigate to="/admin/login" />;
};

export default ProtectedRoute;
