import React from "react";
import { MenuItem, MenuCategory, Restaurant } from "../../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { LazyLoadImage } from "react-lazy-load-image-component";
import generic from "../../../assets/img/burgers/generic.svg";
import 'react-lazy-load-image-component/src/effects/blur.css';

interface MenuItemCardProps {
	item: MenuItem;
	categories: MenuCategory[];
	restaurants: Restaurant[];
	deleteMenuItem: (id: string) => void;
	setEditingItem: (item: MenuItem) => void;
}

const MenuItemCard: React.FC<MenuItemCardProps> = ({ item, categories, restaurants, deleteMenuItem, setEditingItem }) => {
	const itemRestaurants = item.restaurants.map(resId => restaurants.find(res => res.id === resId)?.name || "Unknown").join(", ");

	return (
		<li key={item.id} className="relative border p-4 rounded mb-4 flex items-center space-x-4">
			{/* Thumbnail */}
			<div className="w-28 h-28 flex-shrink-0">
				<LazyLoadImage
					src={item.thumbnailUrl ? item.thumbnailUrl : generic}
					effect="blur"
					className="w-full h-full object-cover rounded"
				/>
			</div>

			{/* Content */}
			<div className="flex-1">
				<h3 className="font-bold font-beastie-bold text-xl">{item.name}</h3>
				<p>Price: ${item.price.toFixed(2)}</p>
				<p>Category: {item.category}</p>
				<p>Ingredients: {item.ingredients.join(", ")}</p>
				<p>Spicy Level: {item.spicyLevel}</p>
				<p>Restaurants: {itemRestaurants}</p>
				<p>Allergens: {item.allergens.join(", ")}</p>
			</div>

			{/* Action Buttons */}
			<div className="flex flex-col space-y-2">
				<button onClick={() => setEditingItem(item)}
				        className="bg-blue-500 text-white px-3 py-1 rounded flex items-center justify-center">
					<FontAwesomeIcon icon={faEdit} />
				</button>
				<button onClick={() => deleteMenuItem(item.id!)}
				        className="bg-red-500 text-white px-3 py-1 rounded flex items-center justify-center">
					<FontAwesomeIcon icon={faTrashCan} />
				</button>
			</div>
		</li>
	);
};

export default MenuItemCard;
