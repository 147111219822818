import React, { useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import { firestore } from "../../../firebaseConfig";
import { Ingredient } from "../../../types";
import {motion} from "framer-motion";

interface IngredientFormProps {
	ingredients: Ingredient[];
	setIngredients: (ingredients: Ingredient[]) => void;
	variants: any
}

const IngredientForm: React.FC<IngredientFormProps> = ({ ingredients, setIngredients, variants }) => {
	const [ingredientName, setIngredientName] = useState<string>("");

	const addIngredient = async () => {
		const newIngredient = { name: ingredientName };
		const docRef = await addDoc(collection(firestore, "menuIngredients"), newIngredient);
		setIngredients([...ingredients, { ...newIngredient, id: docRef.id }]);
		setIngredientName("");
	};

	return (
		<motion.div variants={variants} initial="hidden" animate="visible"
		            className="w-full bg-torch-red-500 rounded-xl shadow">
			<div className="text-5xl text-white p-5">
				<h1 className="font-beastie">BEASTIE</h1>
				<h1 className="font-beastie-bold">NEW INGREDIENT</h1>
			</div>

			<div className="m-5">
				<div className="flex flex-col">
					<label className="font-beastie-bold text-white text-3xl uppercase">Name:</label>
					<input
						type="text"
						placeholder="Ingredient Name"
						value={ingredientName}
						onChange={(e) => setIngredientName(e.target.value)}
						className="border p-2 rounded mb-2"
					/>
				</div>
			</div>

			<button onClick={addIngredient} className="hover:bg-torch-red-500 py-3 px-10 w-auto rounded hover:text-white text-3xl font-beastie-bold bg-white m-5 shadow text-torch-red-500 transition-all cursor-pointer">Add Ingredient
			</button>
		</motion.div>
	);
};

export default IngredientForm;
