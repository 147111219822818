import { useState } from "react";
import { motion } from "framer-motion";
import {addDoc, collection} from "firebase/firestore";
import {firestore} from "../../../firebaseConfig";


function NewLocation({ variants }: any) {
	const [locationName, setLocationName] = useState("");

	const handleSubmit = async () => {
		await addDoc(collection(firestore, "locations"), {
			name: locationName,
		});

		setLocationName("");
	};

	return (
		<motion.div variants={variants} initial="hidden" animate="visible" className="w-full bg-torch-red-500 rounded-xl shadows">
			<div className="text-5xl text-white p-5">
				<h1 className="font-beastie">BEASTIE</h1>
				<h1 className="font-beastie-bold">NEW LOCATION</h1>
			</div>
			<div className="m-5">
				<div className="flex flex-col">
					<label className="font-beastie-bold text-white text-3xl uppercase">New Location:</label>
					<input
						type="text"
						placeholder="ex. ANTWERP"
						className="border rounded-lg w-full p-2 font-beastie-bold text-xl"
						value={locationName}
						onChange={(e) => setLocationName(e.target.value)}
						required
					/>
				</div>
			</div>
			<div className="flex justify-center items-center">
				<div
					onClick={handleSubmit}
					className="hover:bg-torch-red-500 py-3 px-10 rounded hover:text-white text-3xl font-beastie-bold bg-white m-5 shadow text-torch-red-500 transition-all cursor-pointer"
				>
					SHARE
				</div>
			</div>
		</motion.div>
	);
}

export default NewLocation;
