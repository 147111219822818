import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";

import logo from "../../../assets/img/logo-c.png";
import {auth} from "../../../firebaseConfig";
import {useNavigate} from "react-router-dom";

function Login() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState<string | null>(null);
	const navigate = useNavigate();

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		try {
			await signInWithEmailAndPassword(auth, email, password);
			navigate("/admin");
		} catch (error) {
			setError("Failed to sign in. Please check your email and password.");
		}
	};

	return (
		<div className="bg-black min-h-screen flex justify-center items-center">
			<div className="w-1/3 bg-white rounded-2xl flex flex-col items-center p-8">
				<img src={logo} className="w-2/3 mb-8" alt="Beastie Burgers" />
				<form className="w-full" onSubmit={handleSubmit}>
					<div className="mb-4">
						<input
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							placeholder="Email"
							className="w-full p-2.5 bg-gray-800 border border-gray-700 rounded-md text-sm text-gray-100 h-11 transition duration-150 focus:outline-none focus:border-blue-400"
							required
						/>
					</div>
					<div className="mb-6">
						<input
							type="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							placeholder="Password"
							className="w-full p-2.5 bg-gray-800 border border-gray-700 rounded-md text-sm text-gray-100 h-11 transition duration-150 focus:outline-none focus:border-blue-400"
							required
						/>
					</div>
					{error && <p className="mb-4 text-red-500 text-sm">{error}</p>}
					<button
						type="submit"
						className="w-full m-0 p-2 bg-crimson-800 text-white rounded-md h-11 transition duration-150 hover:bg-blue-700 focus:outline-none"
					>
						Sign In
					</button>
				</form>
			</div>
		</div>
	);
}

export default Login;
