import bgDark from "../../assets/img/bg/bg-dark.jpg";
import logo from "../../assets/img/logo-c.png";
import logoWhite from "../../assets/img/logo-white.png";
import rockOnLogo from "../../assets/img/rock-on-logo.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram, faSquareFacebook, faTiktok} from "@fortawesome/free-brands-svg-icons";

function Footer() {
	return (
		<div className=" overflow-hidden text-white" style={{
			backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)), url(${bgDark})`,
			backgroundSize: 'cover'
		}}>
			<div className="grid grid-cols-1 md:grid-cols-5 w-11/12 mx-auto gap-5 py-24">
				<div className="p-5 me-5">
					<img src={logo} className="w-1/2 mx-auto md:w-full" alt="Beastie Burger"/>
				</div>
				<div className="text-xl md:text-start text-center">
					<h1 className="font-bold mb-2 font-beastie-bold text-3xl text-torch-red-500">EAT</h1>
					<ul className="">
						<li className=""><a className="font-beastie-bold text-2xl" href="/menu/select">MENU</a></li>
						<li className=""><a className="font-beastie-bold text-2xl" href="/restaurants">RESTAURANTS</a></li>
						<li className=""><a className="font-beastie-bold text-2xl" href="/menu">BURGERS</a></li>
					</ul>
				</div>
				<div className="text-xl md:text-start text-center">
					<h1 className="font-bold mb-2 font-beastie-bold text-3xl text-torch-red-500">COMMUNITY</h1>
					<ul className="">
						<li><a href="" className="font-beastie tracking-wide">BEASTIE <b className="font-beastie-bold uppercase tracking-wider">Events</b> </a></li>
						<li><a href="/kiosk" className="font-beastie tracking-wide">BEASTIE <b className="font-beastie-bold uppercase tracking-wider">Kiosk</b></a></li>
						<li><a href="/giveaway" className="font-beastie tracking-wide">BEASTIE <b className="font-beastie-bold uppercase tracking-wider">Giveaway</b></a></li>
						<li><a href="/style"  className="font-beastie tracking-wide">BEASTIE <b  className="font-beastie-bold uppercase tracking-wider">Style</b></a></li>
						<li><a href="/press" className="font-beastie tracking-wide">BEASTIE <b className="font-beastie-bold uppercase tracking-wider">Press</b></a></li>

					</ul>
				</div>
				<div className="text-xl md:text-start text-center">
					<h1 className="font-bold mb-2 font-beastie-bold text-3xl text-torch-red-500">INFO</h1>
					<ul className="">
						<li className=""><a className="font-beastie-bold text-2xl" href="/jobs">JOBS</a></li>
						<li className=""><a className="font-beastie-bold text-2xl" href="/franchise">FRANCHISE</a> </li>
						<li className=""><a className="font-beastie-bold text-2xl" href="/story">OUR STORY</a></li>
						<li className=""><a className="font-beastie-bold text-2xl" href="/restaurants">CONTACT US</a></li>
					</ul>
				</div>
				<div className="h-full md:text-start text-center">
					<div className="">
						<div className="font-bold font-beastie-bold text-2xl text-torch-red-500">
							FOLLOW US
						</div>
						<div className="flex w-1/3 mx-auto md:w-full text-white gap-5 justify-center mt-5">
							<FontAwesomeIcon size='3x' icon={faInstagram}/>
							<FontAwesomeIcon size='3x' icon={faSquareFacebook}/>
							<FontAwesomeIcon size='3x' icon={faTiktok}/>
						</div>
					</div>
					<div className="mt-10">
						<div className="font-bold  font-beastie-bold text-2xl text-torch-red-500">
							LANGUAGE
						</div>
						<div className="flex justify-center md:justify-start text-white gap-3 mt-5">
							<div className=" flex font-bold">
								<h1 className=" text-torch-red-500 bg-white rounded-xl p-2 font-beastie-bold text-3xl tracking-wider">NL</h1>
							</div>
							<div className=" flex font-bold">
								<h1 className=" bg-white text-torch-red-500 rounded-xl p-2 font-beastie-bold text-3xl tracking-wider">EN</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="hidden md:flex justify-center items-center mb-16 ">
				<h1 className="text-5xl font-beastie text-torch-red-500">TRY BEFORE IT'S ILLEGAL</h1>
			</div>
			<div className="w-11/12 py-5 mx-auto">
				<ul className="md:ms-10 flex flex-wrap justify-center md:justify-start gap-5">
					<li>Beastie Burgers © 2024</li>
					<li>Privacy policy</li>
					<li>General Terms and Conditions</li>
					<li>Cookie Policy</li>
					<li>Disclaimer</li>
					<li>Customer Service</li>
				</ul>
			</div>
		</div>
	);
}

export default Footer
