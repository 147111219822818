import React, { useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import { firestore } from "../../../firebaseConfig";
import { MenuCategory, Restaurant } from "../../../types";
import { motion } from "framer-motion";

interface CategoryFormProps {
	categories: MenuCategory[];
	setCategories: (categories: MenuCategory[]) => void;
	restaurants: Restaurant[];
	variants: any;
}

const CategoryForm: React.FC<CategoryFormProps> = ({
	                                                   categories,
	                                                   setCategories,
	                                                   restaurants,
	                                                   variants,
                                                   }) => {
	const [categoryName, setCategoryName] = useState<string>("");
	const [selectedRestaurants, setSelectedRestaurants] = useState<number[]>([]);

	const handleRestaurantChange = (restaurantId: number) => {
		setSelectedRestaurants((prevSelected) =>
			prevSelected.includes(restaurantId)
				? prevSelected.filter((id) => id !== restaurantId)
				: [...prevSelected, restaurantId]
		);
	};

	const addCategory = async () => {
		const newCategory = { name: categoryName, restaurants: selectedRestaurants };
		const docRef = await addDoc(collection(firestore, "menuCategories"), newCategory);
		setCategories([...categories, { ...newCategory, id: docRef.id }]);
		setCategoryName("");
		setSelectedRestaurants([]);
	};

	return (
		<motion.div variants={variants} initial="hidden" animate="visible" className="w-full bg-torch-red-500 rounded-xl shadow">
			<div className="text-5xl text-white p-5">
				<h1 className="font-beastie">BEASTIE</h1>
				<h1 className="font-beastie-bold">NEW CATEGORY</h1>
			</div>

			<div className="m-5">
				<div className="flex flex-col">
					<label className="font-beastie-bold text-white text-3xl uppercase">Name:</label>
					<input
						type="text"
						placeholder="Category Name"
						value={categoryName}
						onChange={(e) => setCategoryName(e.target.value)}
						className="border p-2 rounded mb-2"
					/>
				</div>
			</div>

			<div className="m-5">
				<div className="flex flex-col">
					<label className="font-beastie-bold text-white text-3xl uppercase">Restaurants:</label>
					{restaurants.map((res) => (
						<label key={res.id} className="text-white">
							<input
								type="checkbox"
								value={res.id}
								checked={selectedRestaurants.includes(res.id)}
								onChange={() => handleRestaurantChange(res.id)}
								className="mr-2"
							/>
							{res.name}
						</label>
					))}
				</div>
			</div>

			<button
				onClick={addCategory}
				className="hover:bg-torch-red-500 py-3 px-10 w-auto rounded hover:text-white text-3xl font-beastie-bold bg-white m-5 shadow text-torch-red-500 transition-all cursor-pointer"
			>
				Add Category
			</button>
		</motion.div>
	);
};

export default CategoryForm;
