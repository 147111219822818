import React, { useRef, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import Footer from "./components/Footer/Footer";
import FloatingMenu from "./components/FloatingMenu/FloatingMenu";
import { AuthProvider } from "./AuthContext";
import AdminHome from "./pages/admin/Home/Home";
import Login from "./pages/admin/Login/Login";
import ProtectedRoute from "./ProtectedRoute";
import {default as PressAdmin} from "./pages/admin/Press/Press";
import AdminJobs from "./pages/admin/Jobs/AdminJobs";
import AdminMenu from "./pages/admin/Menu/AdminMenu";
import Navbar from "./components/navbar";
import AnimatedRoutes from "./components/AnimatedRoutes";
import AdminGiveaway from "./pages/admin/Giveaway/AdminGiveaway";
import ScrollToTop from "./components/ScrollToTop";
import { SpeedInsights } from "@vercel/speed-insights/react"

function App() {
	const footerRef = useRef(null);
	const [isFooterVisible, setFooterVisible] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setFooterVisible(entry.isIntersecting);

			},
			{ threshold: 0.1 }
		);

		if (footerRef.current) {
			observer.observe(footerRef.current);
		}

		return () => {
			if (footerRef.current) {
				observer.unobserve(footerRef.current);
			}
		};
	}, []);

	return (
		<div className="App">
			<ParallaxProvider>
				<SpeedInsights/>
				<BrowserRouter>
					<AuthProvider>
						<ScrollToTop />
						<Navbar />
						<AnimatedRoutes />
						<Routes>
							<Route path="/admin/login" element={<Login />} />
							<Route element={<ProtectedRoute />}>
								<Route path="/admin" element={<AdminHome />} />
								<Route path="/admin/press" element={<PressAdmin />} />
								<Route path="/admin/jobs" element={<AdminJobs />} />
								<Route path="/admin/menu" element={<AdminMenu />} />
								<Route path="/admin/giveaway" element={<AdminGiveaway />} />
							</Route>
						</Routes>

						<div ref={footerRef}>
							<Footer />
						</div>
					</AuthProvider>
				</BrowserRouter>
			</ParallaxProvider>
			<FloatingMenu isFooterVisible={isFooterVisible} />
		</div>
	);
}

export default App;
