import {NavLink} from "react-router-dom";

export interface NavItemProps {
	name: string;
	link?: string;
}

function NavItem({name, link}: NavItemProps) {
	return (
		<li className="text-white  font-black relative text-nowrap uppercase text-xl w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-crimson-700 after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
			<NavLink className="font-beastie-bold tracking-wide text-3xl" to={link!}>{name}</NavLink>
        </li>
	);
}

export default NavItem
