import {useScroll, useVelocity, motion, AnimatePresence} from "framer-motion";
import React, {useEffect, useState} from "react";
import {useAuth} from "../../AuthContext";
import {signOut} from "firebase/auth";
import {auth} from "../../firebaseConfig";
import logo from "../../assets/img/logo-c.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faTimes} from "@fortawesome/free-solid-svg-icons";
import NavItem from "./NavItem";
import {useLocation} from "react-router-dom";

function HamburgerMenu() {

	const [open, setOpen] = useState(false);

	const slideDistance = 100;
	const threshold = 350;

	const { scrollY } = useScroll();
	const scrollVelocity = useVelocity(scrollY);


	const [isScrollingBack, setIsScrollingBack] = React.useState(false);
	const [isAtTop, setIsAtTop] = React.useState(true);
	const [isInView, setIsInView] = React.useState(true);
	const { currentUser } = useAuth();
	const location = useLocation();

	const handleLogout = async () => {
		try {
			await signOut(auth);
			window.location.href = "/"; // Redirect to home page after logout
		} catch (error) {
			console.error("Failed to sign out:", error);
		}
	};
	useEffect(() => {
		scrollVelocity.onChange((latest) => {
			if (latest > 0) {
				setIsScrollingBack(false);
				return;
			}

			if (latest < -threshold) {
				setIsScrollingBack(true);
				return;
			}
		});
	}, []);

	useEffect(() => {
		setOpen(false);
	}, [location]);



	useEffect(() => scrollY.onChange((latest) => setIsAtTop(latest <= 0)), []);

	useEffect(() => setIsInView(isScrollingBack || isAtTop), [isScrollingBack, isAtTop]);

	return (
		<AnimatePresence>
			<motion.div
				className={`fixed z-[9999] top-0 w-screen flex flex-col items-center bg-black overflow-hidden`}
				animate={{y: isInView ? 0 : -slideDistance, height: open ? 'auto' : isAtTop ? 100 : slideDistance}}
				transition={{duration: 0.2, delay: 0.25, ease: "easeInOut"}}
			>
				<div className=" flex justify-between items-center h-[100px]">
					<a href="/">
						<img src={logo} className="w-[45%] p-5"/>
					</a>
					<div className="text-white me-6">
						<motion.div
							animate={{rotate: open ? 180 : 0}}
							transition={{duration: 0.2, ease: "easeInOut"}}
						>
							<FontAwesomeIcon
								icon={open ? faTimes : faBars}
								size="3x"
								onClick={() => setOpen((prevState) => !prevState)}
							/>
						</motion.div>
					</div>
				</div>

				{open && (
					<div className="h-screen w-screen flex flex-col gap-5 mt-[100px] items-center">
						<NavItem name="Menu" link="menu/select"/>
						<NavItem name="Our Story" link="story"/>
						<NavItem name="Restaurants" link="/restaurants"/>
						<NavItem name="Franchise" link="franchise"/>
						<NavItem name="Community" link="community"/>
						<NavItem name="Jobs" link="jobs"/>
						{currentUser ? (
							<li
								className="bg-torch-red-500 font-beastie-bold text-nowrap tracking-wide uppercase px-4 py-2 rounded-xl border-2 border-transparent hover:border-torch-red-500 hover:bg-black hover:text-torch-red-500 hover:-rotate-6 transition-all cursor-pointer"
								onClick={handleLogout}
							>
								<span className="font-black">Logout</span>
							</li>
						) : (
							<li className="bg-torch-red-500  uppercase text-nowrap px-4 py-2 rounded-xl border-2 border-transparent hover:border-torch-red-500 hover:bg-black hover:text-torch-red-500 hover:-rotate-6 transition-all cursor-pointer">
								<a className="font-black font-beastie-bold tracking-wide text-2xl"
								   href="https://beastie-smash.resos.com/booking">Book a table</a>
							</li>
						)}
					</div>
				)}
			</motion.div>
		</AnimatePresence>
	);
}

export default HamburgerMenu
