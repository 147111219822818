// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


export const firebaseConfig = {
	apiKey: "AIzaSyAl88BuKirXgwRf94XCOlRI29pMRh0Fe1U",
	authDomain: "beastie-burgers.firebaseapp.com",
	projectId: "beastie-burgers",
	storageBucket: "beastie-burgers.appspot.com",
	messagingSenderId: "187162761438",
	appId: "1:187162761438:web:392404ebf38c32feedca4f",
	measurementId: "G-FZK9BS3JED"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

export { app, auth, firestore, storage, analytics };
