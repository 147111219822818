import { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import NewPosition from "./NewPosition";
import NewLocation from "./NewLocation";
import NewJob from "./NewJob";
import Applications from "./Applications";
import { Job, Location, Category } from "../../../types";
import { collection, doc, getDocs, deleteDoc } from "firebase/firestore";
import { firestore } from "../../../firebaseConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

const variants = {
	visible: { opacity: 1, scale: 1 },
	hidden: { opacity: 0, scale: 0 },
};

function AdminJobs() {
	const [activeForm, setActiveForm] = useState(0);
	const [jobs, setJobs] = useState<Job[]>([]);
	const [filteredJobs, setFilteredJobs] = useState<Job[]>([]);
	const [locations, setLocations] = useState<Location[]>([]);
	const [categories, setCategories] = useState<Category[]>([]);
	const [selectedLocation, setSelectedLocation] = useState<string>("ALL");
	const [selectedCategory, setSelectedCategory] = useState<string>("ALL");

	useEffect(() => {
		const fetchData = async () => {
			const jobSnapshot = await getDocs(collection(firestore, "jobs"));
			const locationSnapshot = await getDocs(collection(firestore, "locations"));
			const categorySnapshot = await getDocs(collection(firestore, "categories"));

			const jobsData = jobSnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			})) as Job[];
			const locationsData = locationSnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			})) as Location[];
			const categoriesData = categorySnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			})) as Category[];

			setJobs(jobsData);
			setLocations(locationsData);
			setCategories(categoriesData);
			setFilteredJobs(jobsData);
		};

		fetchData();
	}, []);

	useEffect(() => {
		filterJobs();
	}, [selectedLocation, selectedCategory]);

	const filterJobs = () => {
		let filtered = jobs;
		if (selectedLocation !== "ALL") {
			filtered = filtered.filter((job) => job.location === selectedLocation);
		}
		if (selectedCategory !== "ALL") {
			filtered = filtered.filter((job) => job.category === selectedCategory);
		}
		setFilteredJobs(filtered);
	};

	const handleDelete = async (id: string) => {
		await deleteDoc(doc(firestore, "jobs", id));
		setJobs(jobs.filter((job) => job.id !== id));
		setFilteredJobs(filteredJobs.filter((job) => job.id !== id));
	};

	const [showApplications, setShowApplications] = useState(false);
	const [currentJobId, setCurrentJobId] = useState<string | null>(null);

	const handleViewApplications = (jobId: string) => {
		setCurrentJobId(jobId);
		setShowApplications(true);
	};

	return (
		<div className="min-h-screen pt-44 pb-24">
			<div className="w-[85%] mx-auto mb-5">
				<div className="flex justify-center items-center gap-5">
					<div
						onClick={() => setActiveForm(0)}
						className="flex gap-2 text-2xl mt-4 border p-3 rounded cursor-pointer hover:border-torch-red-500 transition-all"
					>
						<div className="font-beastie-bold tracking-wide">NEW JOB</div>
					</div>
					<div
						onClick={() => setActiveForm(1)}
						className="flex gap-2 text-2xl mt-4 border p-3 rounded cursor-pointer hover:border-torch-red-500 transition-all"
					>
						<div className="font-beastie-bold tracking-wide">NEW POSITION</div>
					</div>
					<div
						onClick={() => setActiveForm(2)}
						className="flex gap-2 text-2xl mt-4 border p-3 rounded cursor-pointer hover:border-torch-red-500 transition-all"
					>
						<div className="font-beastie-bold tracking-wide">NEW LOCATION</div>
					</div>
				</div>
			</div>
			<div className="grid grid-cols-2 gap-10 w-[85%] mx-auto">
				<AnimatePresence>
					{activeForm === 0 && <NewJob variants={variants} active={activeForm} />}
					{activeForm === 1 && <NewPosition variants={variants} active={activeForm} />}
					{activeForm === 2 && <NewLocation variants={variants} active={activeForm} />}
				</AnimatePresence>
				<div className="">
					<div className="uppercase text-torch-red-500">
						<h1 className="font-beastie-bold text-5xl">Currently open positions</h1>
					</div>
					<div className="flex gap-5">
						<select
							className="border p-3 rounded cursor-pointer hover:border-torch-red-500 transition-all"
							onChange={(e) => setSelectedLocation(e.target.value)}
						>
							<option value="ALL">ALL LOCATIONS</option>
							{locations.map((location) => (
								<option key={location.id} value={location.name}>
									{location.name}
								</option>
							))}
						</select>
						<select
							className="border p-3 rounded cursor-pointer hover:border-torch-red-500 transition-all"
							onChange={(e) => setSelectedCategory(e.target.value)}
						>
							<option value="ALL">ALL CATEGORIES</option>
							{categories.map((category) => (
								<option key={category.id} value={category.name}>
									{category.name}
								</option>
							))}
						</select>
					</div>
					<div className="mt-5">
						{filteredJobs.map((job) => (
							<div key={job.id} className="border p-3 rounded mb-2 flex justify-between items-center">
								<div>
									<h2 className=" font-bold font-beastie-bold text-3xl">{job.name}</h2>
									<p>Category: {job.category}</p>
									<p>Location: {job.location}</p>
									<div
										onClick={() => handleViewApplications(job.id!)}
										className="bg-torch-red-500 text-center cursor-pointer text-white px-3 py-1 rounded mt-2"
									>
										View Applications
									</div>
								</div>
								<div
									onClick={() => handleDelete(job.id!)}
									className="bg-torch-red-500 hover:text-torch-red-500 hover:bg-white transition-all active:scale-110 text-white px-3 py-1 rounded"
								>
									<FontAwesomeIcon icon={faTrashCan} />
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			{showApplications && currentJobId && (
				<Applications jobId={currentJobId} setShowApplications={setShowApplications} />
			)}
		</div>
	);
}

export default AdminJobs;
