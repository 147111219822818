import React, { useState, useEffect } from "react";
import { addDoc, updateDoc, collection, doc, getDocs, setDoc } from "firebase/firestore";
import {firestore, storage} from "../../../firebaseConfig";
import { MenuItem, MenuCategory, Ingredient, Restaurant } from "../../../types";
import { motion } from "framer-motion";
import Select from 'react-select';
import {getDownloadURL, ref, uploadBytesResumable, deleteObject, listAll} from "firebase/storage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";

interface MenuFormProps {
	editingItem: MenuItem | null;
	setEditingItem: React.Dispatch<React.SetStateAction<MenuItem | null>>;
	setAddingItem: React.Dispatch<React.SetStateAction<boolean>>;
	categories: MenuCategory[];
	ingredients: Ingredient[];
	restaurants: Restaurant[];
	menuItems: MenuItem[];
	setMenuItems: React.Dispatch<React.SetStateAction<MenuItem[]>>;
	variants: any;
}

const MenuForm: React.FC<MenuFormProps> = ({
	                                           editingItem,
	                                           setEditingItem,
	                                           setAddingItem,
	                                           categories,
	                                           ingredients,
	                                           restaurants,
	                                           menuItems,
	                                           setMenuItems,
	                                           variants,
                                           }) => {
	const initialMenuItemState: MenuItem = {
		id: "",
		name: "",
		price: 0,
		category: "",
		ingredients: [],
		spicyLevel: 0,
		restaurants: [],
		allergens: [],
		description: ""
	};

	const [formItem, setFormItem] = useState<MenuItem>(editingItem || initialMenuItemState);
	const [allergens, setAllergens] = useState<string[]>([]);
	const [thumbnail, setThumbnail] = useState<File | null>(null);
	const [thumbnailPreview, setThumbnailPreview] = useState<string | null>(null);
	const [uploadProgress, setUploadProgress] = useState<number>(0);
	const [thumbnailRemoved, setThumbnailRemoved] = useState<boolean>(false); // New state for thumbnail removal
	const [thumbnailList, setThumbnailList] = useState<{name: string,  url: string}[]>([]); // Store available thumbnails
	const [selectedThumbnail, setSelectedThumbnail] = useState<string | null>(null); // Selected existing thumbnail

	useEffect(() => {
		const fetchAllergens = async () => {
			const allergenSnapshot = await getDocs(collection(firestore, "allergens"));
			const allergenList = allergenSnapshot.docs.map(doc => doc.data().name.toLowerCase());
			setAllergens(allergenList);
		};
		fetchAllergens();
	}, []);

	useEffect(() => {
		if (editingItem) {
			setFormItem(editingItem);
			// Set thumbnail preview if the editing item has a thumbnailUrl
			if (editingItem.thumbnailUrl) {
				setThumbnailPreview(editingItem.thumbnailUrl);
			}
		} else {
			setFormItem(initialMenuItemState);
		}
	}, [editingItem]);

	useEffect(() => {
		const fetchThumbnails = async () => {
			const listRef = ref(storage, "thumbnails/");
			const res = await listAll(listRef);

			const thumbnails = await Promise.all(
				res.items.map(async (item) => ({
					name: item.name, // Store image name
					url: await getDownloadURL(item), // Store download URL
				}))
			);

			setThumbnailList(thumbnails);
		};

		fetchThumbnails();
	}, []);


	const handleThumbnailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			setThumbnail(file);
			setThumbnailPreview(URL.createObjectURL(file));
			setSelectedThumbnail(null); // Reset selected thumbnail if a new one is uploaded
		}
	};


	const handleExistingThumbnailSelect = (url: string) => {
		setSelectedThumbnail(url);
		setThumbnailPreview(url);
		setThumbnail(null); // Clear any uploaded file if an existing thumbnail is selected
	};


	const removeThumbnail = () => {
		setThumbnail(null);
		setThumbnailPreview(null);
		setSelectedThumbnail(null);
		if (editingItem) {
			setFormItem({ ...formItem, thumbnailUrl: undefined });
		}
	};


	const handleCheckboxChange = (list: string[], value: string) => {
		return list.includes(value) ? list.filter(item => item !== value) : [...list, value];
	};

	const handleRestaurantChange = (restaurantId: number) => {
		setFormItem(prevFormItem => ({
			...prevFormItem,
			restaurants: handleCheckboxChange(prevFormItem.restaurants.map(String), String(restaurantId)).map(Number)
		}));
	};

	const handleAllergenChange = (allergenName: string) => {
		setFormItem(prevFormItem => ({
			...prevFormItem,
			allergens: handleCheckboxChange(prevFormItem.allergens, allergenName)
		}));
	};

	const uploadThumbnail = async (): Promise<string | null> => {
		if (!thumbnail) return null;

		const storageRef = ref(storage, `thumbnails/${formItem.name}.jpg`);
		const uploadTask = uploadBytesResumable(storageRef, thumbnail);

		return new Promise((resolve, reject) => {
			uploadTask.on(
				"state_changed",
				(snapshot) => {
					const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					setUploadProgress(progress);
				},
				(error) => {
					console.error("Upload error:", error);
					reject(error);
				},
				async () => {
					const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
					resolve(downloadURL);
				}
			);
		});
	};


	const addOrUpdateMenuItem = async (item: MenuItem) => {
		try {
			// Handle thumbnail replacement if a new thumbnail is provided
			if (thumbnail && editingItem?.thumbnailUrl) {
				const oldThumbnailRef = ref(storage, editingItem.thumbnailUrl);
				await deleteObject(oldThumbnailRef);
				console.log("Old thumbnail successfully deleted.");
			}

			// Upload new thumbnail if present
			if (thumbnail) {
				const newThumbnailUrl = await uploadThumbnail();
				if (newThumbnailUrl) item.thumbnailUrl = newThumbnailUrl;
			} else if (selectedThumbnail) {
				item.thumbnailUrl = selectedThumbnail;
			}

			// Handle item update or addition
			if (item.id) {
				const docRef = doc(firestore, "menuItems", item.id);
				await updateDoc(docRef, { ...item });
				setMenuItems(menuItems.map(mi => (mi.id === item.id ? item : mi)));
			} else {
				const docRef = await addDoc(collection(firestore, "menuItems"), item);
				const newItem = { ...item, id: docRef.id };
				await setDoc(docRef, newItem);
				setMenuItems([...menuItems, newItem]);
			}

			resetForm();
		} catch (error) {
			console.error("Error adding/updating menu item:", error);
		}
	};

	const resetForm = () => {
		setEditingItem(null);
		setAddingItem(false);
		setFormItem(initialMenuItemState);
		setThumbnail(null);
		setThumbnailPreview(null);
		setUploadProgress(0);
		setSelectedThumbnail(null);
	};



	const handleCategoryChange = (e: any) => {
		setFormItem({...formItem, category: e.target.value});
	};

	const handleIngredientChange = (selectedOptions: any) => {
		const selectedIngredients = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
		setFormItem({ ...formItem, ingredients: selectedIngredients });
	};

	const ingredientOptions = ingredients.map((ing) => ({
		value: ing.name,
		label: ing.name
	}));

	const handleSpicyLevelChange = (spicyLevel: number) => {
		setFormItem({ ...formItem, spicyLevel });
	};

	const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const value = e.target.value.slice(0, 130); // Limit to 130 characters
		setFormItem({ ...formItem, description: value });
	};

	const handleThumbnailDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault(); // Prevent default behavior (like opening the file in the browser)
		const file = e.dataTransfer.files?.[0]; // Get the first dropped file
		if (file) {
			setThumbnail(file);
			setThumbnailPreview(URL.createObjectURL(file));
		}
	};

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault(); // Allow drop by preventing default handling
	};

	return (
		<motion.div variants={variants} initial="hidden" animate="visible" className="w-full bg-torch-red-500 rounded-xl shadow">
			<div className="text-5xl text-white p-5">
				<h1 className="font-beastie">BEASTIE</h1>
				<h1 className="font-beastie-bold">NEW MENU ITEM</h1>
			</div>
			<div className="p-4 rounded shadow-lg">
				<form onSubmit={(e) => {
					e.preventDefault();
					addOrUpdateMenuItem(formItem);
				}}>
					{/* Thumbnail Upload */}
					<div className={`flex flex-col mt-5 border-dashed border-4 p-5 rounded-lg 
        ${thumbnail ? 'border-green-500' : 'border-white'} 
        hover:border-torch-red-500 transition-all`} onDragOver={handleDragOver}
					     onDrop={handleThumbnailDrop}>
						<label className="font-beastie-bold text-white text-3xl uppercase">Thumbnail:</label>
						<input type="file" accept="image/*" onChange={handleThumbnailChange} className="hidden"
						       id="thumbnailInput"/>
						<label htmlFor="thumbnailInput"
						       className="cursor-pointer bg-white text-torch-red-500 px-5 py-2 mt-2 rounded shadow">
							Click to Upload or Drag & Drop
						</label>

						{/* Existing Thumbnails Dropdown */}
						<select
							className="mt-2 p-2 rounded"
							value={selectedThumbnail || ""}
							onChange={(e) => {
								const selectedUrl = e.target.value;
								handleExistingThumbnailSelect(selectedUrl);
							}}
						>
							<option value="">Select Existing Thumbnail</option>
							{thumbnailList.map((thumbnail, index) => (
								<option key={index} value={thumbnail.url}>
									{thumbnail.name} {/* Display image name here */}
								</option>
							))}
						</select>

						{/* Thumbnail Preview */}
						{thumbnailPreview && (
							<div className="relative mt-3">
								<img src={thumbnailPreview} alt="Thumbnail Preview"
								     className="w-1/3 object-cover rounded"/>
								<button onClick={removeThumbnail}
								        className="absolute top-0 left-1/3 mt-2 bg-white text-torch-red-500 px-1 rounded">
									<FontAwesomeIcon icon={faClose} size={"lg"}/>
								</button>
							</div>
						)}
					</div>

					<div className="flex flex-col mt-5">
						<label className="font-beastie-bold text-white text-3xl uppercase">Name:</label>
						<input
							type="text"
							placeholder="Name"
							value={formItem.name}
							onChange={(e) => setFormItem({...formItem, name: e.target.value})}
							className="border p-2 rounded mb-2"
						/>
					</div>

					<div className="flex flex-col mt-5">
						<label className="font-beastie-bold text-white text-3xl uppercase">Description:</label>
						<textarea
							placeholder="Description (max 130 characters)"
							value={formItem.description}
							onChange={handleDescriptionChange}
							className="border p-2 rounded mb-2"
							maxLength={130}
						/>
						<p className="text-white font-beastie-bold">{formItem.description?.length}/130</p>
					</div>

					<div className="flex flex-col mt-5">
						<label className="font-beastie-bold text-white text-3xl uppercase">Price:</label>
						<input
							type="number"
							step=".01"
							placeholder="Price"
							value={formItem.price}
							onChange={(e) => setFormItem({...formItem, price: Number(e.target.value)})}
							className="border p-2 rounded mb-2"
						/>
					</div>

					<div className="flex flex-col mt-5">
						<label className="font-beastie-bold text-white text-3xl uppercase">Category:</label>
						<select
							value={formItem.category}
							onChange={handleCategoryChange}
							className="border p-2 rounded mb-2"
						>
							<option value="">Select a category</option>
							{categories.map(cat => (
								<option key={cat.id} value={cat.name}>{cat.name}</option>
							))}
						</select>
					</div>

					<div className="flex flex-col mt-5">
						<label className="font-beastie-bold text-white text-3xl uppercase">Ingredients:</label>
						<Select
							isMulti
							key={formItem.ingredients.length}
							value={ingredientOptions.filter(option => formItem.ingredients.includes(option.value))}
							onChange={handleIngredientChange}
							options={ingredientOptions}
							className="rounded mb-2"
						/>
					</div>

					<div className="flex flex-col mt-5">
						<label className="font-beastie-bold text-white text-3xl uppercase">Spicy Level:</label>
						<div className="flex space-x-4">
							{[0, 1, 2, 3].map(level => (
								<label key={level} className="text-white font-beastie-bold text-xl">
									<input
										type="radio"
										value={level}
										checked={formItem.spicyLevel === level}
										onChange={() => handleSpicyLevelChange(level)}
										className="mr-2"
									/>
									{level}
								</label>
							))}
						</div>
					</div>

					<div className="flex flex-col mt-5">
						<label className="font-beastie-bold text-white text-3xl uppercase">Restaurants:</label>
						<div className="flex gap-5">
							{restaurants.map((res) => (
								<label key={res.id} className="text-white font-beastie-bold text-2xl uppercase">
									<input
										type="checkbox"
										value={res.id}
										checked={formItem.restaurants.includes(res.id)}
										onChange={() => handleRestaurantChange(res.id)}
										className="mr-2 "
									/>
									{res.name}
								</label>
							))}
						</div>
					</div>

					<div className="flex flex-col mt-5">
						<label className="font-beastie-bold text-white text-3xl uppercase">Allergens:</label>
						<div className="grid grid-cols-3 gap-2">
							{allergens.map((allergen, index) => (
								<label key={index} className="text-white font-beastie-bold text-2xl uppercase">
									<input
										type="checkbox"
										value={allergen}
										checked={formItem.allergens.includes(allergen)}
										onChange={() => handleAllergenChange(allergen)}
										className="mr-2"
									/>
									{allergen}
								</label>
							))}
						</div>
					</div>
					{/* Upload progress */}
					{uploadProgress > 0 && (
						<div className="mt-3">
							<label className="text-white font-beastie-bold">Upload
								Progress: {uploadProgress.toFixed(0)}%</label>
							<div className="w-full bg-white rounded-full h-2.5 border">
								<div className="bg-torch-red-500 h-2 rounded-full"
								     style={{width: `${uploadProgress}%`}}></div>
							</div>
						</div>
					)}


					<button
						type="submit"
						className="hover:bg-torch-red-500 py-3 px-10 w-auto rounded hover:text-white text-3xl font-beastie-bold bg-white m-5 shadow text-torch-red-500 transition-all cursor-pointer"
					>
						{editingItem ? "Update Menu Item" : "Add Menu Item"}
					</button>
				</form>
			</div>
		</motion.div>
	);
};

export default MenuForm;
