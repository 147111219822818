import { useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { app } from "../../../firebaseConfig";
import 'react-toastify/dist/ReactToastify.css';

const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);


let categories = [
	"Collabs",
	"Events",
	"Menu",
	"Our Story",
	"Social Club"
];

function Press() {
	const editorRef = useRef(null);
	const [editorText, setEditorText] = useState("");
	const [title, setTitle] = useState("");
	const [category, setCategory] = useState("");
	const [file, setFile] = useState<File | null>(null);
	const [previewUrl, setPreviewUrl] = useState<string | null>(null);
	const [uploadProgress, setUploadProgress] = useState<number>(0);

	const handleEditorChange = (content: any, editor: any) => {
		setEditorText(content);
	};

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const file = e.target.files[0];
			setFile(file);
			const reader = new FileReader();
			reader.onloadend = () => setPreviewUrl(reader.result as string);
			reader.readAsDataURL(file);
		}
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		if (!title || !editorText || !file || !category) {
			alert("Please fill in all fields");
			return;
		}

		try {
			const currentUser = auth.currentUser;

			if (!currentUser) {
				alert("User not logged in");
				return;
			}

			// Generate a random name with the current date
			const randomName = `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
			const fileName = `${randomName}.${file.name.split('.').pop()}`;
			const storageRef = ref(storage, `blog-covers/${fileName}`);
			const uploadTask = uploadBytesResumable(storageRef, file);

			uploadTask.on('state_changed',
				(snapshot) => {
					const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					setUploadProgress(progress);
				},
				(error) => {
					console.error("Error uploading file: ", error);
				},
				async () => {
					const fileURL = await getDownloadURL(uploadTask.snapshot.ref);

					// Save blog post data to Firestore
					await addDoc(collection(db, "blogPosts"), {
						title,
						content: editorText,
						coverImage: fileURL,
						category: category,
						author: {
							uid: currentUser.uid,
							email: currentUser.email,
						},
						createdAt: new Date(),
					});


					setTitle("");
					setEditorText("");
					setFile(null);
					setPreviewUrl(null);
					setCategory("");
					setUploadProgress(0);
				}
			);
		} catch (error) {
			console.error("Error saving blog post: ", error);
		}
	};

	return (
		<div className="bg-white-lilac-50 pt-52 pb-24 flex flex-col justify-center items-center">
			<div className="text-5xl mb-5">
				<h1 className="font-beastie">New Blog Post</h1>
			</div>
			<div className="w-1/2 mx-auto bg-white rounded-xl shadow-lg p-3">
				<form onSubmit={handleSubmit} className="flex flex-col gap-4 mt-4">
					<div className="flex gap-5 items-center">
						<label className="font-beastie-bold text-3xl ms-2 uppercase">
							Title:
						</label>
						<input
							type="text"
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							placeholder="COOOL TITLE GOES HERE MAAAN"
							className="border rounded-lg w-full p-2"
							required
						/>
					</div>
					<div className="border p-5 rounded-2xl">
						<Editor
							apiKey="vll80rck3f39fviylifdsav327qel4w8687iym5i73ptw7sc"
							init={{
								skin: "snow",
								icons: "thin",
								placeholder: "Write text baby... ",
								height: 250,
								menubar: true,
								plugins: [
									"advlist autolink lists link image charmap print preview anchor",
									"searchreplace visualblocks code fullscreen textcolor ",
									"insertdatetime media table paste code help wordcount",
								],
								toolbar:
									"undo redo | styleselect | fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ",
							}}
							onEditorChange={handleEditorChange}
						/>
					</div>
					<div className="flex flex-col">
						<label className="font-beastie-bold text-3xl">Category</label>
						<select onChange={(e) => setCategory(e.target.value)}
						        className="border rounded-lg w-full p-2">
							<option disabled={true} selected={true}>Select Category</option>
							{categories.map(category => (
								<option key={category} value={category}>{category}</option>
							))}
						</select>
					</div>
					<div className="flex flex-col">
						<label className="font-beastie-bold text-3xl">Post Cover</label>
						<input
							type="file"
							onChange={handleFileChange}
							className="block w-full text-sm text-slate-500
                file:mr-4 file:py-2 file:px-4 file:rounded-md
                file:border-0 file:font-beastie-bold file:tracking-wider file:text-lg
                file:bg-crimson-50 file:text-crimson-700
                hover:file:bg-crimson-100"
							required
						/>
						{previewUrl && (
							<div className="mt-4">
								<img src={previewUrl} alt="Preview" className="w-full h-auto rounded-lg" />
							</div>
						)}
					</div>
					<div className="flex">
						<button
							type="submit"
							className="w-1/2 mx-auto bg-crimson-600 text-white font-beastie-bold text-4xl uppercase"
						>
							Save
						</button>
					</div>
					{uploadProgress > 0 && (
						<div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mt-4">
							<div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${uploadProgress}%` }}></div>
						</div>
					)}
				</form>
			</div>
		</div>
	);
}

export default Press;
